const DataFormTest = {
  config: {
    grid: {
      section: {
        spacing: 1,
        columns: 12,
        xs: 12,
        sm: 12,
        md: 10,
        lg: 10,
        xl: 10,
      },
      field: {
        spacing: 1,
        columns: 12,
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
      },
    },
  },
  header: {
    name: "Formulario de Testing",
    company: "Neykos Systems",
    form_code: "ASC-F-054",
    revision: "Rev.7",
    updated: "2021-07-27",
  },
  line: {
    id: 1,
    name: "Linea Z",
  },
  order: {
    id: "",
    number: "150000026",
    product_name: "Ron Havana Club 15a",
    product_code: "50006",
  },
  instance: 1,
  sections: [
    {
      id: 1,
      name: "Primera Seccion",
      fields: [
        {
          id: 1,
          name: "Producto",
          code: "PRODUCT_NAME",
          type: "TEXT",
          value: "R.ABUELO 12 AÑOS 1000ML-VC-37.5-6U CL",
          readonly: true,
        },
        {
          id: 2,
          name: "Fecha de Producción",
          type: "DATE",
          required: true,
        },
        {
          id: 3,
          name: "Presentación",
          type: "TEXT",
          value: "Botella",
          required: true,
        },
        {
          id: 4,
          name: "Linea",
          code: "LINE_NAME",
          type: "TEXT",
          value: "",
          required: true,
        },
        {
          id: 5,
          name: "Hora de Arranque",
          type: "TIME",
          required: true,
        },
        {
          id: 6,
          name: "Observaciones",
          type: "TEXTAREA",
          value: "",
          required: false,
          control: {
            checkbox: true,
            name: "Aplica ",
            invert_mode: false,
            bottom_position: true,
          },
        },
        {
          id: 96,
          name: "Observaciones V2",
          type: "TEXTAREA",
          value: "",
        },
        {
          id: 7,
          name: "Codigo Barras",
          type: "BARCODE",
          required: false,
        },
        {
          id: 97,
          name: "Codigo Barras Cajas",
          type: "BARCODE",
          required: true,
        },

        {
          id: 8,
          name: "Desgarre de Papel",
          type: "RADIO",

          required: false,
          options: [
            { name: "Bueno", value: "GOOD" },
            { name: "Regular", value: "MED" },
            { name: "Malo", value: "BAD" },
          ],
        },
        {
          id: 9,
          name: "Cantidad",
          type: "NUMBER",
          required: true,
          control: {
            checkbox: true,
            name: "No aplica ",
            invert_mode: true,
            bottom_position: true,
          },
        },
        {
          id: 10,
          name: "Leyenda del Destino",
          type: "SELECT",
          value: "",
          required: false,
          options: [
            { name: "OK", value: "OK" },
            { name: "No OK", value: "NO_OK" },
            { name: "N/A", value: "N/A" },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "Segunda Seccion",
      hide: false,
      control: {
        switch: true,
      },
      fields: [
        { id: 11, name: "Nombre", type: "TEXT", value: "", required: true },
        { id: 12, name: "Apellido", type: "TEXT", value: "", required: true },
      ],
      sections: [
        {
          id: 1,
          name: "",
          fields: [
            { id: 13, name: "Hora", type: "TIME" },
            { id: 14, name: "Pallet", type: "TEXT", value: "", required: true },
            {
              id: 15,
              name: "Código de Trazabilidad",
              type: "TEXT",
              value: "",
              required: true,
            },
            {
              id: 16,
              name: "C.de Barra del Producto",
              type: "BARCODE",
              value: "",
            },
            {
              id: 17,
              name: "C.de Barra (Cajetilla)",
              type: "BARCODE",
              value: "",
            },
            {
              id: 18,
              name: "C.de Barra (Corrugado)",
              type: "BARCODE",
              value: "",
            },
            { id: 19, name: "Registro Sanitario", type: "TEXT", value: "" },
          ],
          sections: [
            {
              id: 20,
              name: "Subseccion",
              show_name: true,
              fields: [
                { id: 20, name: "Dirección", type: "TEXT", required: true },
                { id: 21, name: "Comuna", type: "TEXT", required: true },
              ],
            },
          ],
        },
        {
          id: 2,
          name: "Impresión en la Caja",
          show_name: true,
          fields: [
            { id: 22, name: "Fecha", type: "DATE" },
            { id: 23, name: "Hora", type: "TIME" },
            { id: 24, name: "Importado Por", type: "TEXT", value: "" },
            { id: 25, name: "Destino", type: "TEXT", value: "" },
            {
              id: 26,
              name: "N° de las Cajas Inspeccionadas",
              type: "TEXT",
              value: "",
            },
            { id: 27, name: "Fotografía Etiqueta en la Caja", type: "PHOTO" },
          ],
        },
        {
          id: 3,
          name: "",
          fields: [
            { id: 28, name: "Destino", type: "TEXT", value: "" },
            {
              id: 29,
              name: "Leyenda del Destino",
              type: "SELECT",
              value: "",
              required: true,
              options: [
                { name: "OK", value: "OK" },
                { name: "No OK", value: "NO_OK" },
                { name: "N/A", value: "N/A" },
              ],
            },
            {
              id: 30,
              name: "Apariencia del Producto",
              type: "SELECT",
              value: "",
              options: [
                { name: "Buena", value: "GOOD" },
                { name: "Regular +", value: "MEDIUM+" },
                { name: "Regular", value: "MEDIUM" },
                { name: "Regular -", value: "MEDIUM-" },
                { name: "Mala", value: "BAD" },
              ],
            },
            { id: 31, name: "Persona Informada", type: "TEXT", value: "" },
            {
              id: 32,
              name: "Desgarre de Papel",
              type: "SELECT",
              value: "",
              options: [
                { name: "Bueno", value: "GOOD" },
                { name: "Malo", value: "BAD" },
              ],
            },
            {
              id: 33,
              name: "Estado de Inspección",
              type: "SELECT",
              value: "",
              options: [
                { name: "Aprobado", value: "APROVED" },
                { name: "En Proceso", value: "PROCESS" },
                { name: "Detenido", value: "STOPPED" },
              ],
            },
            {
              id: 34,
              name: "Motivo del Estado de Inspección",
              type: "TEXT",
              value: "",
            },
          ],
        },
      ],
    },
    { id: 3, name: "Tercera Seccion" },
    { id: 4, name: "Cuarta Seccion" },
  ],
};

export default DataFormTest;
