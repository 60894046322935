const DataFormLine = {
  config: {
    grid: {
      section: {
        spacing: 1,
        columns: 12,
        xs: 12,
        sm: 12,
        md: 10,
        lg: 10,
        xl: 10,
      },
      field: {
        spacing: 1,
        columns: 12,
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
      },
    },
  },
  header: {
    name: "Formulario de Inspección de Línea TEST",
    company: "Varela Hermanos S.A.",
    form_code: "ASC-F-054",
    revision: "Rev.7",
    updated: "2021-07-27",
  },
  line: {
    id: 0,
    name: "Linea Z",
  },
  order: {
    id: "",
    number: "150000026",
    product_name: "Ron Havana Club 15a",
    product_code: "50006",
  },
  instance: 1,
  sections: [
    {
      id: 1,
      name: "Información de Operaciones",
      fields: [
        {
          id: 1,
          name: "Producto",
          code: "PRODUCT_NAME",
          type: "TEXT",
          value: "",
          readonly: true,
        },
        {
          id: 2,
          name: "Fecha de Producción",
          type: "DATE",
          readonly: true,
        },
        {
          id: 3,
          name: "Presentación",
          type: "TEXT",
          value: "",
          readonly: true,
        },
        {
          id: 4,
          name: "Linea",
          code: "LINE_NAME",
          type: "TEXT",
          value: "",
          readonly: true,
        },
        {
          id: 5,
          name: "Hora de Arranque",
          type: "TIME",
          readonly: true,
        },
      ],
    },
    {
      id: 2,
      name: "Verificación del Sistema de Trasiego",
      fields: [
        {
          id: 6,
          name: "Línea de trasiego",
          type: "RADIO",
          required: true,
          value: "",
          options: [
            { name: "OK", value: "OK" },
            { name: "No OK", value: "NO_OK" },
          ],
        },
        {
          id: 7,
          name: "Mangueras",
          type: "RADIO",
          required: true,
          value: "",
          options: [
            { name: "OK", value: "OK" },
            { name: "No OK", value: "NO_OK" },
          ],
        },
        {
          id: 8,
          name: "Bombas",
          type: "RADIO",
          required: true,
          value: "",
          options: [
            { name: "OK", value: "OK" },
            { name: "No OK", value: "NO_OK" },
          ],
        },
        {
          id: 9,
          name: "Filtros",
          type: "RADIO",
          required: true,
          value: "",
          options: [
            { name: "OK", value: "OK" },
            { name: "No OK", value: "NO_OK" },
          ],
        },
      ],
    },
    {
      id: 3,
      name: "Inspección de Máquimas para el Arranque",
      fields: [
        {
          name: "Nivel de llenado",
          type: "RADIO",
          required: true,
          options: [
            { name: "OK", value: "OK" },
            { name: "No OK", value: "NO_OK" },
          ],
        },
        {
          name: "Tapado",
          type: "RADIO",
          required: true,
          options: [
            { name: "OK", value: "OK" },
            { name: "No OK", value: "NO_OK" },
          ],
        },
        {
          name: "Etiquetado",
          type: "RADIO",
          required: true,
          options: [
            { name: "OK", value: "OK" },
            { name: "No OK", value: "NO_OK" },
          ],
        },
        {
          name: "Codificado de envase",
          type: "RADIO",
          required: true,
          options: [
            { name: "OK", value: "OK" },
            { name: "No OK", value: "NO_OK" },
          ],
        },
        {
          name: "Codificado cajas",
          type: "RADIO",
          required: true,
          options: [
            { name: "OK", value: "OK" },
            { name: "No OK", value: "NO_OK" },
          ],
        },
      ],
    },
    {
      id: 4,
      name: "Análisis Fisico-Quimico Para el arranque",
      fields: [
        {
          name: "Grado Alcohólico Aparente",
          type: "TEXT",
          value: "",
        },
        {
          name: "Dentro del Rango",
          type: "RADIO",
          required: true,
          options: [
            { name: "OK", value: "OK" },
            { name: "No OK", value: "NO_OK" },
          ],
        },
        {
          name: "pH",
          type: "TEXT",
          value: "",
        },
        {
          name: "Prueba Organoléptica",
          type: "RADIO",
          required: true,
          options: [
            { name: "OK", value: "OK" },
            { name: "No OK", value: "NO_OK" },
          ],
        },
      ],
    },
    {
      id: 5,
      name: "Información de Suministro",
      fields: [
        {
          name: "Hora",
          type: "TIME",
          value: "",
        },
      ],
      sections: [
        {
          name: "Etiquetas",
          show_name: true,
          sections: [
            {
              name: "Frente",
              control: {
                switch: true,
              },
              fields: [
                {
                  name: "Proveedor",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "OT/OC",
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "OT", value: "OT" },
                    { name: "OC", value: "OC" },
                  ],
                },
                {
                  name: "Número",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "Forma de Pegado",
                  required: true,
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "Autoadhesivo", value: "AUT" },
                    { name: "Cola Fría", value: "COLA" },
                  ],
                },
              ],
            },
            {
              name: "Recetario",
              control: {
                switch: true,
              },
              fields: [
                {
                  name: "Proveedor",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "OT/OC",
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "OT", value: "OT" },
                    { name: "OC", value: "OC" },
                  ],
                },
                {
                  name: "Número",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "Forma de Pegado",
                  required: true,
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "Autoadhesivo", value: "AUT" },
                    { name: "Cola Fría", value: "COLA" },
                  ],
                },
              ],
            },
            {
              name: "Cuello",
              control: {
                switch: true,
              },
              fields: [
                {
                  name: "Proveedor",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "OT/OC",
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "OT", value: "OT" },
                    { name: "OC", value: "OC" },
                  ],
                },
                {
                  name: "Número",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "Forma de Pegado",
                  required: true,
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "Autoadhesivo", value: "AUT" },
                    { name: "Cola Fría", value: "COLA" },
                  ],
                },
              ],
            },
            {
              name: "Hombro",
              control: {
                switch: true,
              },
              fields: [
                {
                  name: "Proveedor",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "OT/OC",
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "OT", value: "OT" },
                    { name: "OC", value: "OC" },
                  ],
                },
                {
                  name: "Número",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "Forma de Pegado",
                  required: true,
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "Autoadhesivo", value: "AUT" },
                    { name: "Cola Fría", value: "COLA" },
                  ],
                },
              ],
            },
            {
              name: "Óvalo",
              control: {
                switch: true,
              },
              fields: [
                {
                  name: "Proveedor",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "OT/OC",
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "OT", value: "OT" },
                    { name: "OC", value: "OC" },
                  ],
                },
                {
                  name: "Número",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "Forma de Pegado",
                  required: true,
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "Autoadhesivo", value: "AUT" },
                    { name: "Cola Fría", value: "COLA" },
                  ],
                },
              ],
            },
            {
              name: "Strip",
              control: {
                switch: true,
              },
              fields: [
                {
                  name: "Proveedor",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "OT/OC",
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "OT", value: "OT" },
                    { name: "OC", value: "OC" },
                  ],
                },
                {
                  name: "Número",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "Forma de Pegado",
                  required: true,
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "Autoadhesivo", value: "AUT" },
                    { name: "Cola Fría", value: "COLA" },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "Pegamento",
          show_name: true,
          fields: [
            {
              name: "Tipo de Goma",
              type: "TEXT",
              value: "",
            },
            {
              name: "Fecha de Producción",
              type: "DATE",
              value: "",
            },
            {
              name: "Fecha de Expiración",
              type: "DATE",
              value: "",
            },
          ],
        },
        {
          name: "Caja de Cartón Corrugado",
          show_name: true,
          fields: [
            {
              name: "Proveedor",
              type: "TEXT",
              value: "",
            },
            {
              name: "Fecha de Producción",
              type: "DATE",
              value: "",
            },
            {
              name: "Sellado",
              type: "TEXT",
              value: "",
            },
          ],
        },
        {
          name: "TAPAS",
          show_name: true,
          fields: [
            {
              name: "Proveedor",
              type: "TEXT",
              value: "",
            },
            {
              name: "Modelo",
              type: "TEXT",
              value: "",
            },
            {
              name: "NR/OP",
              type: "TEXT",
              value: "",
            },
            {
              name: "Fecha de Producción",
              type: "DATE",
              value: "",
            },
          ],
        },
        {
          name: "Tipo de Envase",
          show_name: true,
          fields: [
            {
              name: "Proveedor",
              type: "TEXT",
              value: "",
            },
            {
              name: "Fecha de Producción",
              type: "DATE",
              value: "",
            },

            {
              name: "VR/PET/Otros",
              type: "TEXT",
              value: "",
            },
            {
              name: "Tipo de Lavado",
              type: "TEXT",
              value: "",
            },
          ],
        },
      ],
    },
    {
      id: 6,
      name: "Calculo de Nivel de LLenado",

      fields: [
        {
          name: "Tanque",
          type: "TEXT",
          value: "",
        },
        {
          name: "Lote",
          type: "TEXT",
          value: "",
        },
        {
          name: "Día de Preparación",
          type: "DATE",
          value: "",
        },
        {
          name: "Horas de Reposo",
          type: "NUMBER",
          value: "",
        },
        {
          name: "Alcoholímetro",
          type: "NUMBER",
          value: "",
        },
        {
          name: "Temperatura",
          type: "NUMBER",
          value: "",
        },
        {
          name: "Grado Alcohólico",
          type: "NUMBER",
          value: "",
        },
        {
          name: "pH",
          type: "TEXT",
          value: "",
        },
        {
          name: "Líne / T. de Licor",
          type: "TEXT",
          value: "",
        },
        {
          name: "Destino",
          type: "TEXT",
          value: "",
        },
        {
          name: "Balanza N° de Serie",
          type: "TEXT",
          value: "",
        },
        {
          name: "Peso de Tapas",
          type: "NUMBER",
          value: "",
        },
        {
          name: "Peso de Envases Vacíos",
          type: "NUMBER",
          value: "",
        },
        {
          name: "Peso de Envases Llenos",
          type: "NUMBER",
          value: "",
        },
        {
          name: "Peso Neto Actual",
          type: "NUMBER",
          value: "",
        },
        {
          name: "Peso Ideal",
          type: "NUMBER",
          value: "",
        },
        {
          name: "Dif. Peso Neto",
          type: "NUMBER",
          value: "",
        },
        {
          name: "Límites",
          type: "TEXT",
          value: "",
        },
        {
          name: "Diferencia Porcentual",
          type: "NUMBER",
          value: "",
        },
      ],
    },
    {
      id: 7,
      name: "Personal de Línea",
      fields: [
        {
          name: "1° Inspección",
          type: "TEXT",
          value: "",
        },
        {
          name: "Llenadora",
          type: "TEXT",
          value: "",
        },
        {
          name: "2° Inspección",
          type: "TEXT",
          value: "",
        },
        {
          name: "Tapadora",
          type: "TEXT",
          value: "",
        },
        {
          name: "Etiquetadora",
          type: "TEXT",
          value: "",
        },
        {
          name: "Mesa de Inspección",
          type: "TEXT",
          value: "",
        },
        {
          name: "Recogiendo",
          type: "TEXT",
          value: "",
        },
        {
          name: "Estibando",
          type: "TEXT",
          value: "",
        },
      ],
    },
    {
      id: 8,
      name: "Observaciónes",

      fields: [
        {
          name: "Observaciones",
          type: "TEXTAREA",
          value: "",
          control: {
            checkbox: true,
            invert_mode: true,
            bottom_position: true,
            name: "Sin Observaciones",
          },
        },
      ],
    },

    {
      id: 9,
      name: "Firmas",
      fields: [
        {
          name: "Inspector (a)",
          type: "SIGN",
          value: "",
        },
        {
          name: "Verificado",
          type: "SIGN",
          value: "",
        },
        {
          name: "Informado",
          type: "SIGN",
          value: "",
        },
      ],
    },
  ],
};
export default DataFormLine;
