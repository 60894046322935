import React from "react";

const TicketShow = ({ ticket }) => {
  return (
    <div>
      <h2>Ticket Details</h2>
      <p>Title: {ticket.title}</p>
      <p>Description: {ticket.description}</p>
      <p>Status: {ticket.status}</p>
      <p>Priority: {ticket.priority}</p>
      {/* Add more ticket details here */}
    </div>
  );
};

export default TicketShow;
