import React, { useState } from "react";

const TicketEdit = () => {
  const [ticket, setTicket] = useState({
    title: "",
    description: "",
    priority: "",
  });

  const handleChange = (e) => {
    setTicket({ ...ticket, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic to update the ticket here
    console.log("Ticket updated:", ticket);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Title:
        <input
          type="text"
          name="title"
          value={ticket.title}
          onChange={handleChange}
        />
      </label>
      <br />
      <label>
        Description:
        <textarea
          name="description"
          value={ticket.description}
          onChange={handleChange}
        />
      </label>
      <br />
      <label>
        Priority:
        <select name="priority" value={ticket.priority} onChange={handleChange}>
          <option value="">Select Priority</option>
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
        </select>
      </label>
      <br />
      <button type="submit">Update Ticket</button>
    </form>
  );
};

export default TicketEdit;
