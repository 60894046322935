const DataOrders = {
  completed: [
    {
      id: 1400,
      number: "205300004406",
      product_name: "R.ABUELO AÑEJO 1000ML-VC-37.5-12U PA",
      product_code: "100147",
      form_product: true,
      form_line: false,
      show_buttons_form: true,
    },
    {
      id: 1400,
      number: "205300004405",
      product_name: "R.ABUELO AÑEJO 1000ML-VC-37.5-12U MX",
      product_code: "100154",
      form_product: false,
      form_line: true,
      show_buttons_form: false,
    },
  ],
  running: [
    {
      id: 1400,
      number: "205300004407",
      product_name: "R.ABUELO AÑEJO 1000ML-VC-37.5-12U CL",
      product_code: "100150",

      form_product: false,
      form_line: false,
      show_buttons_form: true,
    },
  ],
};

export default DataOrders;
