/**
 * Component PdfGenerate
 * @author: Luis Avello
 * @version 1.3.0
 * @updated 2023-09-29
 */
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import DataFormEmpty from "./data_form_empty";
import PdfProduct from "./pdf_product";
import PdfLine from "./pdf_line";
import PdfEmpty from "./pdf_empty";
import PdfViewer from "./pdfview/pdf_viewer";
import axios from "axios";
import { responseInfo, responseError } from "../utils/response_console";

import FormSignDialog from "../views/quality/form_sign_dialog";

const responsive = {
  xs: "repeat(1, 1fr)",
  sm: "repeat(2, 1fr)",
  md: "repeat(2, 1fr)",
  lg: "repeat(2, 1fr)",
  xl: "repeat(3, 1fr)",
};

const PdfGenerate = (props) => {
  const { formmain_id, show_init = false } = props;

  const [data, setData] = useState(DataFormEmpty);
  const [datapdf, setDatapdf] = useState(PdfEmpty());
  const [show, setShow] = useState(show_init);
  const [openSignDialog, setOpenSignDialog] = React.useState(false);

  const urlDataPdf = "/api/react/v1/quality/data_pdf";

  useEffect(() => {
    if (formmain_id > 0)
      axios
        .get(urlDataPdf, {
          params: { formmain_id: formmain_id },
        })
        .then((response) => {
          responseInfo(response, true);
          setData(response.data);
          setShow(true);
          if (response.data.code == "FINISHED_PRODUCT")
            setDatapdf(PdfProduct(response.data));
          else {
            setDatapdf(PdfLine(response.data));
          }
        })
        .catch((error) => {
          responseError(error);
        });
  }, []);

  const handleBack = () => {
    window.history.back();
  };

  const handleSign = () => {
    console.log("Sign PDF");
    setOpenSignDialog(true);
    //window.location = "/quality/dash";
  };

  return (
    <Box
      sx={{
        mt: 2,
        mr: 4,
        ml: 4,
        mb: 4,
      }}
    >
      {/* HEADER */}
      <Paper elevation={1} sx={{ p: 1, mb: 3, mt: 3 }}>
        <Typography variant="h5">{"PDF " + data.header.name}</Typography>
      </Paper>
      <Paper elevation={2} sx={{ mb: 3, mt: 3 }}>
        {show ? (
          <PdfViewer
            data={datapdf}
            width="95%"
            height="800"
            style={{ margin: "1%" }}
          ></PdfViewer>
        ) : null}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: responsive,
            gridTemplateAreas: {
              xs: `"buttons"`,
              sm: `" .  buttons"`,
              md: " .  buttons",
              lg: ". . buttons",
              xl: `". . buttons"`,
            },
            gap: 3,
          }}
        >
          <Box
            align="right"
            sx={{
              display: "grid",
              margin: "2%",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridArea: "buttons",
            }}
          >
            <a href="#">
              <Button onClick={handleBack} sx={{ mt: 1 }}>
                Volver
              </Button>
            </a>
            <a href="#">
              <Button onClick={handleSign} variant="contained" sx={{ mt: 1 }}>
                Firmar
              </Button>
            </a>
          </Box>
        </Box>
      </Paper>

      <FormSignDialog
        openDialog={openSignDialog}
        handleOpenDialog={(newState) => setOpenSignDialog(newState)}
        formmain_id={formmain_id}
      />
    </Box>
  );
};

export default PdfGenerate;
