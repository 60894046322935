import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";

const BasicDatePicker = (props) => {
  const { date, handleChange } = props;

  useEffect(() => {
    dayjs.locale("es");
    console.log("Seteo ES");
  }, []);

  return (
    <Stack direction="row" spacing={0}>
      <Tooltip title="Día Anterior">
        <IconButton
          color="primary"
          aria-label="Prev"
          onClick={() => {
            handleChange(date.subtract(1, "day"));
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Tooltip>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Dia"
          inputFormat="DD/MM/YYYY"
          value={date}
          onChange={(newValue) => handleChange(newValue)}
          renderInput={(params) => (
            <TextField size="small" sx={{ width: "140px" }} {...params} />
          )}
        />
      </LocalizationProvider>
      <Tooltip title="Día Siguiente">
        <IconButton
          color="primary"
          aria-label="Next"
          onClick={() => {
            handleChange(date.add(1, "day"));
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default BasicDatePicker;
