const DataFormProduct = {
  config: {
    grid: {
      section: {
        spacing: 1,
        columns: 12,
        xs: 12,
        sm: 12,
        md: 10,
        lg: 10,
        xl: 10,
      },
      field: {
        spacing: 2,
        columns: 12,
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
      },
    },
  },
  header: {
    name: "Formulario de Inspección de Producto Terminado TEST",
    company: "Varela Hermanos S.A.",
    form_code: "ASC-F-005",
    revision: "Rev.7",
    updated: "2021-04-27",
  },
  line: {
    id: 0,
    name: "Linea Z",
  },
  order: {
    id: "",
    number: "150000026",
    product_name: "Ron Havana Club 15a",
    product_code: "50006",
  },
  instance: 1,
  sections: [
    {
      id: 1,
      name: "General",
      fields: [
        { id: 1, name: "Fecha", type: "DATE", readonly: true },
        {
          id: 2,
          name: "Producto",
          code: "PRODUCT_NAME",
          type: "TEXT",
          value: "R.ABUELO 12 AÑOS 1000ML-VC-37.5-6U CL",
          readonly: true,
        },
        {
          id: 3,
          name: "Presentación",
          type: "TEXT",
          value: "1000 ML",
          readonly: true,
        },
        {
          id: 4,
          name: "Línea",
          code: "LINE_NAME",
          type: "TEXT",
          value: "Linea 1",
          readonly: true,
        },
        { id: 5, name: "Inspector(a)", type: "TEXT", value: "Alexis Pérez" },
        {
          id: 6,
          name: "Material en SAP",
          code: "PRODUCT_CODE",
          type: "TEXT",
          value: "100046",
          readonly: true,
        },
        {
          id: 7,
          name: "Tipo de Inspección",
          type: "RADIO",
          value: "",
          required: true,
          options: [
            { name: "Producción", value: "production" },
            { name: "Retrabajo (Producto en Proceso)", value: "rework" },
            {
              name: "Re-inspección por Detención",
              value: "reinspection",
            },
            { name: "Tranferencia de Inventario", value: "transfer" },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "Información del Producto",
      sections: [
        {
          id: 1,
          name: "",
          fields: [
            { id: 8, name: "Hora", type: "TIME" },
            { id: 9, name: "Pallet", type: "TEXT", value: "", required: true },
            {
              id: 10,
              name: "Código de Trazabilidad",
              type: "TEXT",
              value: "",
              required: true,
            },
            {
              id: 11,
              name: "C.de Barra del Producto",
              type: "BARCODE",
              value: "",
            },
            {
              id: 12,
              name: "C.de Barra (Cajetilla)",
              type: "BARCODE",
              value: "",
            },
            {
              id: 13,
              name: "C.de Barra (Corrugado)",
              type: "BARCODE",
              value: "",
            },
            { id: 14, name: "Registro Sanitario", type: "TEXT", value: "" },
          ],
        },
        {
          id: 2,
          name: "Impresión en la Caja",
          show_name: true,
          fields: [
            { id: 14, name: "Fecha", type: "DATE" },
            { id: 15, name: "Hora", type: "TIME" },
            { id: 16, name: "Importado Por", type: "TEXT", value: "" },
            { id: 17, name: "Destino", type: "TEXT", value: "" },
            {
              id: 18,
              name: "N° de las Cajas Inspeccionadas",
              type: "TEXT",
              value: "",
            },
            { id: 19, name: "Fotografía Etiqueta en la Caja", type: "PHOTO" },
          ],
        },
        {
          id: 3,
          name: "",
          fields: [
            { id: 20, name: "Destino", type: "TEXT", value: "" },
            {
              id: 21,
              name: "Leyenda del Destino",
              type: "SELECT",
              value: "",
              options: [
                { name: "OK", value: "OK" },
                { name: "No OK", value: "NO_OK" },
                { name: "N/A", value: "N/A" },
              ],
            },
            {
              id: 22,
              name: "Apariencia del Producto",
              type: "SELECT",
              value: "",
              options: [
                { name: "Buena", value: "GOOD" },
                { name: "Regular +", value: "MEDIUM+" },
                { name: "Regular", value: "MEDIUM" },
                { name: "Regular -", value: "MEDIUM-" },
                { name: "Mala", value: "BAD" },
              ],
            },
            { id: 23, name: "Persona Informada", type: "TEXT", value: "" },
            {
              id: 24,
              name: "Desgarre de Papel",
              type: "SELECT",
              value: "",
              options: [
                { name: "Bueno", value: "GOOD" },
                { name: "Malo", value: "BAD" },
              ],
            },
            {
              id: 25,
              name: "Estado de Inspección",
              type: "SELECT",
              value: "",
              options: [
                { name: "Aprobado", value: "APROVED" },
                { name: "En Proceso", value: "PROCESS" },
                { name: "Detenido", value: "STOPPED" },
              ],
            },
            {
              id: 26,
              name: "Motivo del Estado de Inspección",
              type: "TEXT",
              value: "",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: "Información Adicional de Suministros",
      sections: [
        {
          name: "Etiquetas",
          show_name: true,
          fields: [
            {
              name: "Hora",
              type: "TIME",
              value: "",
            },
          ],
          sections: [
            {
              name: "Frente",
              control: {
                switch: true,
              },
              fields: [
                {
                  name: "Proveedor",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "Fecha de Producción",
                  type: "DATE",
                  value: "",
                },
                {
                  name: "Forma de Pegado",
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "Autoadhesivo", value: "AUT" },
                    { name: "Cola Fría", value: "COLA" },
                  ],
                },
              ],
            },
            {
              name: "Recetario",
              control: {
                switch: true,
              },
              fields: [
                {
                  name: "Proveedor",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "Fecha de Producción",
                  type: "DATE",
                  value: "",
                },
                {
                  name: "Forma de Pegado",
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "Autoadhesivo", value: "AUT" },
                    { name: "Cola Fría", value: "COLA" },
                  ],
                },
              ],
            },
            {
              name: "Cuello",
              control: {
                switch: true,
              },
              fields: [
                {
                  name: "Proveedor",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "Fecha de Producción",
                  type: "DATE",
                  value: "",
                },
                {
                  name: "Forma de Pegado",
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "Autoadhesivo", value: "AUT" },
                    { name: "Cola Fría", value: "COLA" },
                  ],
                },
              ],
            },
            {
              name: "Hombro",
              control: {
                switch: true,
              },
              fields: [
                {
                  name: "Proveedor",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "Fecha de Producción",
                  type: "DATE",
                  value: "",
                },
                {
                  name: "Forma de Pegado",
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "Autoadhesivo", value: "AUT" },
                    { name: "Cola Fría", value: "COLA" },
                  ],
                },
              ],
            },
            {
              name: "Óvalo",
              control: {
                switch: true,
              },
              fields: [
                {
                  name: "Proveedor",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "Fecha de Producción",
                  type: "DATE",
                  value: "",
                },
                {
                  name: "Forma de Pegado",
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "Autoadhesivo", value: "AUT" },
                    { name: "Cola Fría", value: "COLA" },
                  ],
                },
              ],
            },
            {
              name: "Strip",
              control: {
                switch: true,
              },
              fields: [
                {
                  name: "Proveedor",
                  type: "TEXT",
                  value: "",
                },
                {
                  name: "Fecha de Producción",
                  type: "DATE",
                  value: "",
                },
                {
                  name: "Forma de Pegado",
                  type: "RADIO",
                  value: "",
                  options: [
                    { name: "Autoadhesivo", value: "AUT" },
                    { name: "Cola Fría", value: "COLA" },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "Cajetilla Individual",
          show_name: true,
          fields: [
            {
              name: "Hora",
              type: "TIME",
              value: "",
            },

            {
              name: "Fecha de Producción",
              type: "DATE",
              value: "",
            },
            {
              name: "Proveedor",
              type: "TEXT",
              value: "",
            },
          ],
        },
      ],
    },

    {
      id: 4,
      name: "Defectos Envases",
      control: {
        checkbox: true,
        invert_mode: true,
        name: "Sin Defectos",
      },
      sections: [
        {
          id: 4,
          name: "Críticos",
          show_name: true,
          fields: [
            {
              id: 27,
              name: "Columpio (A)",
              type: "NUMBER",
              value: "0",
            },
            {
              id: 28,
              name: "Rajaduras (B)",
              type: "NUMBER",
              value: "0",
            },
            {
              id: 29,
              name: "Aguja (C)",
              type: "NUMBER",
              value: "0",
            },
            {
              id: 30,
              name: "Aristas Cortante (D)",
              type: "NUMBER",
              value: "0",
            },
            {
              id: 31,
              name: "Vidrio Suelto (E)",
              type: "NUMBER",
              value: "0",
            },
            {
              id: 32,
              name: "Vidrio Adherido (F)",
              type: "NUMBER",
              value: "0",
            },
            {
              id: 33,
              name: "Puente Roto (G)",
              type: "NUMBER",
              value: "0",
            },
          ],
        },
        {
          id: 5,
          name: "Defectos Mayores",
          show_name: true,
          fields: [
            {
              id: 34,
              name: "Envase Rayado (H)",
              type: "NUMBER",
              value: "0",
            },
            {
              id: 35,
              name: "Falta de Codificación (I)",
              type: "NUMBER",
              value: "0",
            },
            {
              id: 36,
              name: "Envase Manchado (J)",
              type: "NUMBER",
              value: "0",
            },
          ],
        },
        {
          id: 6,
          name: "Defectos Menores",
          show_name: true,
          fields: [
            {
              id: 37,
              name: "Disparejo (K)",
              type: "NUMBER",
              value: "0",
            },
            {
              id: 38,
              name: "Fracturas que no afectan la Resistencia (L)",
              type: "NUMBER",
              value: "0",
            },
          ],
        },
        {
          id: 7,
          name: "",
          fields: [
            {
              id: 39,
              name: "Otros (Z)",
              type: "NUMBER",
              value: "0",
            },
          ],
        },
      ],
    },
    {
      id: 5,
      name: "Defectos Líquidos",
      control: {
        checkbox: true,
        invert_mode: true,
        name: "Sin Defectos",
      },
      sections: [
        {
          id: 8,
          name: "Críticos",
          show_name: true,
          fields: [
            {
              id: 40,
              name: "Color no Característico (M)",
              type: "NUMBER",
              value: "0",
            },
            {
              id: 41,
              name: "Materia extraña (N)",
              type: "NUMBER",
              value: "0",
            },
          ],
        },
        {
          id: 9,
          name: "Defectos Mayores",
          show_name: true,
          fields: [
            {
              id: 42,
              name: "Nivel Alto (O)",
              type: "NUMBER",
              value: "0",
            },
            {
              id: 43,
              name: "Nivel Bajo (P)",
              type: "NUMBER",
              value: "0",
            },
          ],
        },
        {
          id: 10,
          name: "",
          fields: [
            {
              id: 44,
              name: "Otros (Z)",
              type: "NUMBER",
              value: "0",
            },
          ],
        },
      ],
    },
    {
      id: 6,
      name: "Defectos Tapas",
      control: {
        checkbox: true,
        invert_mode: true,
        name: "Sin Defectos",
      },
      sections: [
        {
          name: "Tapas",
          control: {
            switch: true,
          },
          sections: [
            {
              name: "Defectos Mayores",
              show_name: true,
              fields: [
                {
                  name: "Liqueo en Tapas (Q)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Fecha Incorrecta (R)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Tapa Rota (S)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Diferencia Excesiva Tono de Color (T)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Puente Roto (U)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Código Incorrecto (V)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              name: "Defectos Menores",
              show_name: true,
              fields: [
                {
                  name: "Tapa Floja (W)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              name: "",
              fields: [
                {
                  name: "Otros (Z)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
          ],
        },
        {
          name: "Corcho",
          control: {
            switch: true,
          },
          sections: [
            {
              name: "Defectos Críticos",
              show_name: true,
              fields: [
                {
                  name: "Cantidad",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Observaciones",
                  type: "TEXTAREA",
                  value: "",
                  control: {
                    checkbox: true,
                    invert_mode: true,
                    bottom_position: true,
                    name: "Sin Observaciones",
                  },
                },
              ],
            },
            {
              name: "Defectos Mayores",
              show_name: true,
              fields: [
                {
                  name: "Cantidad",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Observaciones",
                  type: "TEXTAREA",
                  value: "",
                  control: {
                    checkbox: true,
                    invert_mode: true,
                    bottom_position: true,
                    name: "Sin Observaciones",
                  },
                },
              ],
            },
            {
              name: "Defectos Menores",
              show_name: true,
              fields: [
                {
                  name: "Cantidad",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Observaciones",
                  type: "TEXTAREA",
                  value: "",
                  control: {
                    checkbox: true,
                    invert_mode: true,
                    bottom_position: true,
                    name: "Sin Observaciones",
                  },
                },
              ],
            },
          ],
        },
        {
          name: "Cápsula",
          control: {
            switch: true,
          },
          sections: [
            {
              name: "Defectos Críticos",
              show_name: true,
              fields: [
                {
                  name: "Cantidad",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Observaciones",
                  type: "TEXTAREA",
                  value: "",
                  control: {
                    checkbox: true,
                    invert_mode: true,
                    bottom_position: true,
                    name: "Sin Observaciones",
                  },
                },
              ],
            },
            {
              name: "Defectos Mayores",
              show_name: true,
              fields: [
                {
                  name: "Cantidad",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Observaciones",
                  type: "TEXTAREA",
                  value: "",
                  control: {
                    checkbox: true,
                    invert_mode: true,
                    bottom_position: true,
                    name: "Sin Observaciones",
                  },
                },
              ],
            },
            {
              name: "Defectos Menores",
              show_name: true,
              fields: [
                {
                  name: "Cantidad",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Observaciones",
                  type: "TEXTAREA",
                  value: "",
                  control: {
                    checkbox: true,
                    invert_mode: true,
                    bottom_position: true,
                    name: "Sin Observaciones",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 7,
      name: "Defectos Etiquetas",
      control: {
        checkbox: true,
        invert_mode: true,
        name: "Sin Defectos",
      },
      sections: [
        {
          name: "Etiqueta Frente",
          control: {
            switch: true,
          },
          sections: [
            {
              name: "Defectos Mayores",
              show_name: true,
              fields: [
                {
                  name: "Etiqueta Torcida (X)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Defecto de Impresión (AH)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Equivocada (Y)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Color Incorrecto (AI)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Falta de Etiqueta (AA)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Invertidas (AJ)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Leyenda Equivocada (AB)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Diferencia Excesiva en el Tono de Color (AK)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Arrugada (AC)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              name: "Defectos Menores",
              show_name: true,
              fields: [
                {
                  name: "Etiqueta Rota (AD)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Sucia (AL)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Exceso de Pegamento (AE)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Pelada (AM)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Falta de Pegamento (AF)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Eiqueta Doblada (AN)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Fuera de Centro (AG)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              id: 1,
              name: "",
              fields: [
                {
                  name: "Otros (Z)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
          ],
        },
        {
          name: "Etiqueta Recetario",
          control: {
            switch: true,
          },
          sections: [
            {
              name: "Defectos Mayores",
              show_name: true,
              fields: [
                {
                  name: "Etiqueta Torcida (X)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Defecto de Impresión (AH)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Equivocada (Y)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Color Incorrecto (AI)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Falta de Etiqueta (AA)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Invertidas (AJ)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Leyenda Equivocada (AB)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Diferencia Excesiva en el Tono de Color (AK)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Arrugada (AC)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              name: "Defectos Menores",
              show_name: true,
              fields: [
                {
                  name: "Etiqueta Rota (AD)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Sucia (AL)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Exceso de Pegamento (AE)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Pelada (AM)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Falta de Pegamento (AF)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Eiqueta Doblada (AN)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Fuera de Centro (AG)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              id: 1,
              name: "",
              fields: [
                {
                  name: "Otros (Z)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
          ],
        },
        {
          name: "Etiqueta Strip",
          control: {
            switch: true,
          },
          sections: [
            {
              name: "Defectos Mayores",
              show_name: true,
              fields: [
                {
                  name: "Etiqueta Torcida (X)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Defecto de Impresión (AH)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Equivocada (Y)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Color Incorrecto (AI)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Falta de Etiqueta (AA)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Invertidas (AJ)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Leyenda Equivocada (AB)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Diferencia Excesiva en el Tono de Color (AK)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Arrugada (AC)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              name: "Defectos Menores",
              show_name: true,
              fields: [
                {
                  name: "Etiqueta Rota (AD)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Sucia (AL)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Exceso de Pegamento (AE)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Pelada (AM)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Falta de Pegamento (AF)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Eiqueta Doblada (AN)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Fuera de Centro (AG)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              id: 1,
              name: "",
              fields: [
                {
                  name: "Otros (Z)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
          ],
        },
        {
          name: "Etiqueta Hombro",
          control: {
            switch: true,
          },
          sections: [
            {
              name: "Defectos Mayores",
              show_name: true,
              fields: [
                {
                  name: "Etiqueta Torcida (X)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Defecto de Impresión (AH)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Equivocada (Y)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Color Incorrecto (AI)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Falta de Etiqueta (AA)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Invertidas (AJ)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Leyenda Equivocada (AB)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Diferencia Excesiva en el Tono de Color (AK)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Arrugada (AC)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              name: "Defectos Menores",
              show_name: true,
              fields: [
                {
                  name: "Etiqueta Rota (AD)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Sucia (AL)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Exceso de Pegamento (AE)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Pelada (AM)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Falta de Pegamento (AF)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Eiqueta Doblada (AN)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Fuera de Centro (AG)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              id: 1,
              name: "",
              fields: [
                {
                  name: "Otros (Z)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
          ],
        },
        {
          name: "Etiqueta Cuello",
          control: {
            switch: true,
          },
          sections: [
            {
              name: "Defectos Mayores",
              show_name: true,
              fields: [
                {
                  name: "Etiqueta Torcida (X)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Defecto de Impresión (AH)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Equivocada (Y)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Color Incorrecto (AI)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Falta de Etiqueta (AA)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Invertidas (AJ)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Leyenda Equivocada (AB)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Diferencia Excesiva en el Tono de Color (AK)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Arrugada (AC)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              name: "Defectos Menores",
              show_name: true,
              fields: [
                {
                  name: "Etiqueta Rota (AD)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Sucia (AL)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Exceso de Pegamento (AE)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Pelada (AM)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Falta de Pegamento (AF)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Eiqueta Doblada (AN)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Fuera de Centro (AG)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              id: 1,
              name: "",
              fields: [
                {
                  name: "Otros (Z)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
          ],
        },
        {
          name: "Etiqueta Óvalo",
          control: {
            switch: true,
          },
          sections: [
            {
              name: "Defectos Mayores",
              show_name: true,
              fields: [
                {
                  name: "Etiqueta Torcida (X)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Defecto de Impresión (AH)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Equivocada (Y)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Color Incorrecto (AI)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Falta de Etiqueta (AA)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Invertidas (AJ)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Leyenda Equivocada (AB)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Diferencia Excesiva en el Tono de Color (AK)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Arrugada (AC)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              name: "Defectos Menores",
              show_name: true,
              fields: [
                {
                  name: "Etiqueta Rota (AD)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Sucia (AL)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Exceso de Pegamento (AE)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Etiqueta Pelada (AM)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Falta de Pegamento (AF)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Eiqueta Doblada (AN)",
                  type: "NUMBER",
                  value: "0",
                },
                {
                  name: "Fuera de Centro (AG)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
            {
              id: 1,
              name: "",
              fields: [
                {
                  name: "Otros (Z)",
                  type: "NUMBER",
                  value: "0",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 8,
      name: "Resumen Inspección",

      sections: [
        {
          name: "Envases",
          show_name: true,
          fields: [
            {
              name: "Menor",
              type: "NUMBER",
              value: "0",
              readonly: true,
            },
            {
              name: "Mayor",
              type: "NUMBER",
              value: "0",
              readonly: true,
            },
            {
              name: "Critico",
              type: "NUMBER",
              value: "0",
              readonly: true,
            },
          ],
        },
        {
          name: "Líquidos",
          show_name: true,
          fields: [
            {
              name: "Menor",
              type: "NUMBER",
              value: "0",
              readonly: true,
            },
            {
              name: "Mayor",
              type: "NUMBER",
              value: "0",
              readonly: true,
            },
            {
              name: "Critico",
              type: "NUMBER",
              value: "0",
              readonly: true,
            },
          ],
        },
        {
          name: "Tapas",
          show_name: true,
          fields: [
            {
              name: "Menor",
              type: "NUMBER",
              value: "0",
              readonly: true,
            },
            {
              name: "Mayor",
              type: "NUMBER",
              value: "0",
              readonly: true,
            },
            {
              name: "Critico",
              type: "NUMBER",
              value: "0",
              readonly: true,
            },
          ],
        },
        {
          name: "Etiquetas",
          show_name: true,
          fields: [
            {
              name: "Menor",
              type: "NUMBER",
              value: "0",
              readonly: true,
            },
            {
              name: "Mayor",
              type: "NUMBER",
              value: "0",
              readonly: true,
            },
            {
              name: "Critico",
              type: "NUMBER",
              value: "0",
              readonly: true,
            },
          ],
        },
        {
          name: "Resumen",
          show_name: true,
          fields: [
            {
              name: "Cantidad Inspeccionada",
              type: "NUMBER",
              value: "0",
            },
            {
              name: "Total Defectos Critico",
              type: "NUMBER",
              value: "0",
            },
            {
              name: "Total Defectos Mayores",
              type: "NUMBER",
              value: "0",
            },
            {
              name: "Total Defectos Menores",
              type: "NUMBER",
              value: "0",
            },
            {
              name: "Crear Ticket",
              type: "BUTTON",
              value: "0",
            },
          ],
        },
      ],
    },
    {
      id: 9,
      name: "Resumen Formulario",
      fields: [
        {
          name: "Total Defectos",
          type: "NUMBER",
          value: "0",
        },
        {
          name: "Total Unidades Defectuosas Critico",
          type: "NUMBER",
          value: "0",
        },
        {
          name: "Total Unidades Defectuosas Mayores",
          type: "NUMBER",
          value: "0",
        },
        {
          name: "Total Unidades Defectuosas Menores",
          type: "NUMBER",
          value: "0",
        },
        {
          name: "Observaciones",
          type: "TEXTAREA",
          value: "",
          control: {
            checkbox: true,
            invert_mode: true,
            bottom_position: true,
            name: "Sin Observaciones",
          },
        },
      ],
    },
    {
      id: 10,
      name: "Firmas",
      fields: [
        {
          name: "Revisado Por",
          type: "SIGN",
          value: "",
        },
        {
          name: "Informado",
          type: "SIGN",
          value: "",
        },
      ],
    },
  ],
};

export default DataFormProduct;
