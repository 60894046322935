/**
 * Component FormSignDialog
 * @author: Luis Avello
 * @version 1.1.0
 * @updated 2023-09-29
 */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { responseInfo, responseError } from "../../utils/response_console";

const FormSignDialog = (props) => {
  const { openDialog, handleOpenDialog, formmain_id } = props;
  const [pin, setPin] = useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageSnack, setMessageSnack] = React.useState("");
  const urlInspectors = "/api/react/v1/quality/inspectors";
  const urlLogin = "/api/react/v1/quality/login";
  const urlCurrentUserSign = "/api/react/v1/quality/current_user_sign";
  const urlSign = "/api/react/v1/quality/sign";
  const [user, setUser] = useState({ id: 0, name: "", role: { name: "" } });

  useEffect(() => {
    axios
      .get(urlCurrentUserSign, {
        params: {},
      })
      .then((response) => {
        responseInfo(response, true);
        setUser(response.data);
      })
      .catch((error) => {
        responseError(error);
      });
  }, []);

  const handleCloseDialog = () => {
    console.log("Close Dialog Login");
    //setOpenDialog(false);
  };

  const handleChangeField = (event) => {
    console.log("onChangeField: " + event.target.value);
    setPin(event.target.value);
  };

  const handleLogin = () => {
    console.log("Login");

    axios
      .get(urlSign, {
        params: { user_id: user.id, pin: pin, formmain_id: formmain_id },
      })
      .then((response) => {
        responseInfo(response, true);
        //handleLoginResult(response.data.result);
        //handleNameInspector(response.data.name);
        if (response.data.result) {
          //setMessageSnack("Bienvenido");
          //setOpenSnack(true);
          handleOpenDialog(false);
          window.location.reload();
        } else {
          console.log("Error pin");
          setMessageSnack("ERROR: PIN Incorrecto");
          setOpenSnack(true);
        }
      })
      .catch((error) => {
        responseError(error);
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Firmar Formulario</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {user.name}
          <br />
          <Typography variant="subtitle2">{user.role.name}</Typography>
        </DialogContentText>
        <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
          <TextField
            id="outlined-password-input"
            label="PIN"
            type="password"
            autoComplete="current-password"
            onChange={handleChangeField}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleLogin} autoFocus>
          Firmar
        </Button>
        <Button onClick={() => handleOpenDialog(false)}>Cancelar</Button>
      </DialogActions>

      <Snackbar
        open={openSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        onClose={handleCloseSnack}
      >
        <Alert
          variant="filled"
          onClose={handleCloseSnack}
          severity="info"
          sx={{ width: "100%" }}
        >
          {messageSnack}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default FormSignDialog;
