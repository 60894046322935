/**
 * Component FormDetail
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2023-08-11
 */
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import KeyIcon from "@mui/icons-material/Key";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";

import { red, purple, indigo, blueGrey } from "@mui/material/colors";

const FormDetail = (props) => {
  const { form, dummyMode = false, handleClose } = props;
  const color_edit = indigo[500];
  const color_pdf = red[600];
  const color_sign = purple[700];
  const color_close = blueGrey[700];

  const handleClickPdf = (formmain_id) => {
    console.log("Pdf " + formmain_id);
    window.location = "/quality/pdf?formmain_id=" + formmain_id;
  };

  const handleClickEdit = (formmain_id) => {
    console.log("Edit " + formmain_id);
    window.location = "/quality/form?formmain_id=" + formmain_id;
  };

  const handleClickSign = (formmain_id) => {
    console.log("Sign " + formmain_id);
  };

  const handleClickClose = () => {
    console.log("Close detail");
    handleClose();
  };

  return (
    <Card sx={{ boxShadow: 1 }}>
      <CardContent>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6" component="div">
            ID {form.id} {form.type}
          </Typography>
          <Tooltip title="Cerrar" arrow>
            <IconButton aria-label="edit" onClick={() => handleClickClose()}>
              <CloseIcon sx={{ color: color_close }} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Typography variant="body2" color="text.secondary">
          {form.line}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Producto {form.product}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Ordenes de Producción {form.orders}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Inspecciones {form.inspections}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Fecha creación {form.date_created}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {form.state}
        </Typography>
      </CardContent>
      <CardActions>
        <Tooltip title="Editar Formulario" arrow>
          <IconButton
            aria-label="edit"
            onClick={() => handleClickEdit(form.id)}
          >
            <EditIcon sx={{ color: color_edit }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Ver PDF" arrow>
          <IconButton aria-label="pdf" onClick={() => handleClickPdf(form.id)}>
            <PictureAsPdfIcon sx={{ color: color_pdf }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Firmar Formulario" arrow>
          <IconButton
            aria-label="sign"
            onClick={() => handleClickSign(form.id)}
          >
            <KeyIcon sx={{ color: color_sign }} />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default FormDetail;
