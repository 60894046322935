/**
 * Component FormField
 * @author: Luis Avello
 * @version 1.14.1
 * @updated 2023-07-18
 */
import React, { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import MicIcon from "@mui/icons-material/Mic";
import KeyIcon from "@mui/icons-material/Key";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Snackbar from "@mui/material/Snackbar";
import { red, blue } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "regenerator-runtime";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import { Html5Qrcode } from "html5-qrcode";
import dayjs from "dayjs";
import "dayjs/locale/es";
import axios from "axios";
import { responseInfo, responseError } from "../utils/response_console";

import NewTicket from "../tickets/new_ticket";

const FormField = (props) => {
  const { field, grid, handleError, index, validate, forminstance_id } = props;
  field.control || (field.control = {});
  // Chequear si es necesario mantener el estado
  const [value, setValue] = React.useState("");
  //const [datetime, setDatetime] = React.useState(field.value || dayjs());
  const [datetime, setDatetime] = React.useState("");

  const urlValueField = "/api/react/v1/quality/value_field";
  const urlSetValueField = "/api/react/v1/quality/set_value_field";

  const [showField, setShowField] = useState(
    !field.hide == !field.control.invert_mode
  );
  const [errorField, setErrorField] = useState(field.error);
  const [helperText, setHelperText] = useState("");
  const msg_required = "Por favor complete el campo requerido";

  const handleChangeDatetime = (newDatetime) => {
    //setValueField(newDatetime.format("YYYY-MM-DD HH:mm:ss"));
    console.log("newDatetime: " + newDatetime);
    if (newDatetime != null && !isNaN(newDatetime)) {
      setDatetime(newDatetime);
      //console.log("format: " + newDatetime.format());
      setValueField(newDatetime.format());
    } else console.log("newDatetime is not a number");
  };

  const checkErrorField = (actualErrorField) => {
    if (actualErrorField != errorField) {
      console.log(
        `Field ${field.type} ${field.name} Error change to ${actualErrorField}`
      );
      setErrorField(actualErrorField);
      handleError(index, actualErrorField);
      actualErrorField ? setHelperText(msg_required) : setHelperText("");
    }
  };

  const handleChangeShowField = (event) => {
    setShowField(event.target.checked);
    let actualErrorField =
      field.required &&
      event.target.checked == !field.control.invert_mode &&
      event.target.value == "";
    checkErrorField(actualErrorField);
  };

  const handleOnAcceptPicker = () => {
    if (field.required) {
      setErrorField(false);
      setHelperText("");
      handleError(index, false);
    }
  };

  const handleBlurField = (event) => {
    if (field.required) {
      let actualErrorField = event.target.value == "";
      checkErrorField(actualErrorField);
    }
  };

  useEffect(() => {
    if (forminstance_id > 0)
      axios
        .get(urlValueField, {
          params: {
            forminstance_id: forminstance_id,
            formfield_id: field.id,
          },
        })
        .then((response) => {
          responseInfo(response, true);
          switch (field.type) {
            case "DATE":
            case "TIME":
              setDatetime(dayjs(response.data.value));
              break;
            default:
              setValue(response.data.value);
              break;
          }
        })
        .catch((error) => {
          responseError(error);
        });
  }, [forminstance_id]);

  useEffect(() => {
    if (validate > 0) {
      console.log(
        "Validate " + validate + " Field " + field.type + " Index " + index
      );
      if (field.required && showField == !field.control.invert_mode) {
        let actualErrorField = false;
        switch (field.type) {
          case "DATE":
          case "TIME":
            actualErrorField = datetime == "";
            checkErrorField(actualErrorField);

            break;
          default:
            actualErrorField = value == "";
            checkErrorField(actualErrorField);

            break;
        }
      }
    }
  }, [validate]);

  /*const handleFocusField = (event) => {
     console.log('onFocusField: ' + event.target.value);
   };*/

  const handleChangeField = (event) => {
    console.log("onChangeField: " + event.target.value);
    setValue(event.target.value);
    setValueField(event.target.value);
  };

  const setValueField = (new_value) => {
    axios
      .get(urlSetValueField, {
        params: {
          forminstance_id: forminstance_id,
          formfield_id: field.id,
          value: new_value,
        },
      })
      .then((response) => {
        responseInfo(response, true);
      })
      .catch((error) => {
        responseError(error);
      });
  };

  let field_render = <Box></Box>;
  switch (field.type) {
    case "LABEL":
      break;
    case "TEXT":
      field_render = (
        <TextField
          fullWidth
          required={field.required}
          disabled={field.disabled}
          error={errorField}
          InputProps={{
            readOnly: field.readonly,
            startAdornment: field.prefix ? (
              <InputAdornment position="start">{field.prefix}</InputAdornment>
            ) : null,
            endAdornment: field.suffix ? (
              <InputAdornment position="end">{field.suffix}</InputAdornment>
            ) : null,
          }}
          size="small"
          label={field.name}
          value={value}
          variant="outlined"
          onChange={handleChangeField}
          //onFocus={handleFocusField}
          onBlur={handleBlurField}
          helperText={helperText}
        />
      );
      break;
    case "TEXTAREA":
      const [activeSpeech, setActiveSpeech] = useState(0);
      const {
        transcript,
        finalTranscript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
      } = useSpeechRecognition();

      const handleBlurTextField = (event) => {
        console.log("handleBlurTextField");
        setActiveSpeech(0);
        if (field.required) {
          let actualErrorField = event.target.value == "";
          checkErrorField(actualErrorField);
        }
      };

      const handleFocusTextField = (event) => {
        console.log("handleFocusTextField");
        setActiveSpeech(0);
      };

      /*useEffect(() => {
         console.log('activeSpeech ' + activeSpeech + ' field.id ' + field.id);
         if (activeSpeech == field.id) {
           console.log(
             'Campo id ' + field.id + ' Cambio transcript a ' + transcript
           );
           setValue(transcript);
         } else console.log('Speech de otro campo');
       }, [transcript]);*/

      useEffect(() => {
        console.log("Speech Final " + field.id);
        if (activeSpeech == field.id) {
          console.log(
            "Campo id " +
              field.id +
              " Cambio finalTranscript a " +
              finalTranscript
          );
          setValue(finalTranscript);
          setValueField(finalTranscript);
        } else console.log("Speech de otro campo");
      }, [finalTranscript]);

      const handleListeningSpeech = (event) => {
        setActiveSpeech(field.id);
        console.log("setActiveSpeech a " + field.id);
        if (listening) {
          SpeechRecognition.stopListening();
          console.log(" Microfono Deteniendo");
        } else {
          SpeechRecognition.startListening({ language: "es-US" });
          console.log(" Microfono Escuchando");
        }
      };
      field_render = (
        <Stack direction="row" spacing={0}>
          <TextField
            fullWidth
            required={field.required}
            disabled={field.disabled}
            error={errorField}
            InputProps={{
              readOnly: field.readonly,
            }}
            multiline
            rows={4}
            size="small"
            label={field.name}
            value={value}
            variant="outlined"
            onChange={handleChangeField}
            onBlur={handleBlurTextField}
            onFocus={handleFocusTextField}
            helperText={listening ? "Micrófono Encendido..." : helperText}
          />
          {browserSupportsSpeechRecognition ? (
            <Tooltip title="Micrófono">
              <IconButton
                color="primary"
                aria-label="Prev"
                onClick={handleListeningSpeech}
              >
                <MicIcon
                  sx={listening ? { color: red[500] } : { color: blue[500] }}
                />
              </IconButton>
            </Tooltip>
          ) : null}
        </Stack>
      );
      break;
    case "NUMBER":
      field_render = (
        <TextField
          fullWidth
          required={field.required}
          disabled={field.disabled}
          error={errorField}
          InputProps={{
            readOnly: field.readonly,
            startAdornment: field.prefix ? (
              <InputAdornment position="start">{field.prefix}</InputAdornment>
            ) : null,
            endAdornment: field.suffix ? (
              <InputAdornment position="end">{field.suffix}</InputAdornment>
            ) : null,
          }}
          type="number"
          size="small"
          label={field.name}
          value={value}
          variant="outlined"
          onChange={handleChangeField}
          onBlur={handleBlurField}
          helperText={helperText}
        />
      );
      break;
    case "BARCODE":
      const windowSize = useRef([window.innerWidth, window.innerHeight]);
      const ratio = windowSize.current[0] / windowSize.current[1];
      const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        height: "50%",
        bgcolor: "background.paper",
        border: "2px solid #2596be",
        boxShadow: 20,
        p: 1,
      };
      const [open, setOpen] = useState(false);
      const [openSnack, setOpenSnack] = useState(false);
      const { vertical, horizontal } = {
        vertical: "bottom",
        horizontal: "center",
      };

      const handleOpenScan = (input) => {
        setOpen(true);
      };

      const handleOpenSnack = () => {
        setOpenSnack(true);
      };

      const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }

        setOpenSnack(false);
      };

      const handleClose = () => {
        setOpen(false);
      };

      const handleCloseSuccess = () => {
        setOpen(false);
        handleOpenSnack();
      };

      const handleOnClickScan = () => {
        console.log("Open Scanner");
      };

      const BarcodeScannerPlugin = () => {
        const qrcodeId = "reader-" + field.id;

        let html5QrCode;

        useEffect(() => {
          // Anything in here is fired on component mount.
          console.log(qrcodeId);
          if (!html5QrCode?.getState()) {
            html5QrCode = new Html5Qrcode(qrcodeId);
            const qrCodeSuccessCallback = (decodedText, decodedResult) => {
              console.log(
                "decodedText: " +
                  decodedText +
                  " decodedResult: " +
                  decodedResult
              );
              setValue(decodedText);
              setValueField(decodedText);
              if (field.required && decodedText != "") {
                setErrorField(false);
                setHelperText("");
                handleError(index, false);
              }

              html5QrCode.stop();
              handleCloseSuccess();
            };

            const config = {
              fps: 10,
              qrbox: 225,
              aspectRatio: ratio,
              forgetLastUsedCamera: false,
            };

            // If you want to prefer back camera
            html5QrCode.start(
              { facingMode: "environment" },
              config,
              qrCodeSuccessCallback
            );
          }

          return () => {
            // Anything in here is fired on component unmount.
          };
        }, []);

        return (
          <div
            style={{ width: "80%", margin: "auto", marginTop: 0 }}
            id={qrcodeId}
          ></div>
        );
      };

      field_render = (
        <Stack direction="row" spacing={0}>
          <TextField
            fullWidth
            required={field.required}
            disabled={field.disabled}
            error={errorField}
            InputProps={{
              readOnly: field.readonly,
            }}
            size="small"
            label={field.name}
            value={value}
            variant="outlined"
            onChange={handleChangeField}
            onBlur={handleBlurField}
            helperText={helperText}
          />
          <Tooltip title="Escanear Código">
            <IconButton
              color="primary"
              aria-label="Prev"
              onClick={handleOpenScan}
            >
              <QrCodeScannerIcon />
            </IconButton>
          </Tooltip>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
          >
            <Fade in={open}>
              <Box sx={style}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item>
                    <Typography marginTop={0}>
                      Posicione el código en el marco para escanear
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <BarcodeScannerPlugin />
                <Grid margin={"auto"} container justifyContent={"right"}>
                  <Grid item marginTop={1} marginRight={1}>
                    <Button variant="contained" onClick={handleClose}>
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Fade>
          </Modal>
        </Stack>
      );
      break;
    case "SIGN":
      field_render = (
        <Stack direction="row" spacing={0}>
          <TextField
            fullWidth
            required={field.required}
            disabled={field.disabled}
            error={field.error}
            InputProps={{
              readOnly: field.readonly,
            }}
            size="small"
            label={field.name}
            value={value}
            variant="outlined"
          />
          <Tooltip title="Firmar Formulario">
            <IconButton
              color="primary"
              aria-label="Prev"
              onClick={() => {
                console.log("Scan");
              }}
            >
              <KeyIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      );
      break;
    case "PHOTO":
      field_render = (
        <Stack direction="row" spacing={0}>
          <Typography variant="caption" align="center" mt={1.2}>
            {field.name}
          </Typography>
          <Tooltip title="Tomar Fotografía">
            <IconButton
              color="primary"
              aria-label="Photo"
              onClick={() => {
                console.log("Take Photo");
              }}
            >
              <PhotoCameraIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      );
      break;

    case "BUTTON":
      /*field_render = (
 
         <Button variant="contained" size="small" sx={{ mt: 1, mr: 1 }}>
           {field.name}
         </Button>
       );*/
      field_render = <NewTicket forminstance_id={forminstance_id} />;
      break;
    case "DATE":
      field_render = (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disabled={field.disabled}
            readOnly={field.readonly}
            label={field.name}
            inputFormat="DD/MM/YYYY"
            value={datetime}
            onChange={(newValue) => handleChangeDatetime(newValue)}
            onAccept={handleOnAcceptPicker}
            renderInput={(params) => (
              <TextField
                fullWidth
                size="small"
                {...params}
                required={field.required}
                error={errorField}
                onBlur={handleBlurField}
                helperText={helperText}
              />
            )}
          />
        </LocalizationProvider>
      );
      break;
    case "TIME":
      field_render = (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            disabled={field.disabled}
            readOnly={field.readonly}
            label={field.name}
            value={datetime}
            ampm={false}
            onChange={(newValue) => handleChangeDatetime(newValue)}
            onAccept={handleOnAcceptPicker}
            renderInput={(params) => (
              <TextField
                fullWidth
                size="small"
                {...params}
                required={field.required}
                error={errorField}
                onBlur={handleBlurField}
                helperText={helperText}
              />
            )}
          />
        </LocalizationProvider>
      );
      break;
    case "CHECKBOX": // Completar, debe ser checkbox multiples con titulo
      const [checked, setChecked] = React.useState(field.value);
      const handleChangeCheckbox = (event) => {
        setChecked(event.target.checked);
      };

      field_render = (
        <FormGroup>
          <FormControlLabel
            disabled={field.disabled | field.readonly}
            control={
              <Checkbox
                checked={checked}
                onChange={handleChangeCheckbox}
                size="small"
                required={field.required}
              />
            }
            label={field.name}
          />
        </FormGroup>
      );
      break;
    case "SWITCH":
      const [checkedSwitch, setCheckedSwitch] = React.useState(field.value);
      const handleChangeSwitch = (event) => {
        setCheckedSwitch(event.target.checked);
      };
      field_render = (
        <FormGroup>
          <FormControlLabel
            disabled={field.disabled | field.readonly}
            control={
              <Switch
                size="small"
                checked={checkedSwitch}
                onChange={handleChangeSwitch}
              />
            }
            label={field.name}
          />
        </FormGroup>
      );
      break;
    case "RADIO":
      const handleChangeRadio = (event) => {
        setValue(event.target.value);
        console.log("Seteo Radio a valor " + event.target.value);
        if (field.required) {
          let actualErrorField =
            event.target.value === "" || event.target.value === undefined;
          checkErrorField(actualErrorField);
          /*setErrorField(false);
           setHelperText('');
           handleError(index, false);*/
        }
        setValueField(event.target.value);
      };
      const handleClickRadio = (event) => {
        console.log("Radio event:" + event.target.value);
        console.log("Radio value:" + value);
        if (field.required) {
          let actualErrorField =
            value === "" || event.target.value === undefined;
          checkErrorField(actualErrorField);
        }
        /*if (
           field.required &&
           value === '' &&
           event.target.value === undefined
         ) {
           setErrorField(true);
           setHelperText(msg_required);
           handleError(index, true);
         }*/
      };

      field_render = (
        <FormControl
          fullWidth
          required={field.required}
          disabled={field.disabled | field.readonly}
          error={errorField}
          onClick={handleClickRadio}
        >
          <FormLabel id={"pms-radio-buttons-group-label-" + field.id}>
            {field.name}
          </FormLabel>
          <RadioGroup
            aria-labelledby={"pms-radio-buttons-group-label-" + field.id}
            name={"pms-radio-buttons-group-" + field.id}
            value={value}
            onChange={handleChangeRadio}
            row={field.row_direction}
          >
            {field.options.map((option, index) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio size="small" />}
                label={option.name}
              />
            ))}
          </RadioGroup>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      );
      break;
    case "SELECT":
      const handleCloseSelect = (event) => {
        console.log("Close select event:" + event.target.value);
        if (field.required) {
          let actualErrorField =
            event.target.value === "" || event.target.value === undefined;
          checkErrorField(actualErrorField);
        }

        //setValueField(event.target.value);

        /*if (
           field.required &&
           (event.target.value === '' || event.target.value === undefined)
         ) {
           console.log('Set error');
           setErrorField(true);
           setHelperText(msg_required);
           handleError(index, true);
         } else {
           console.log('No error');
           setErrorField(false);
           setHelperText('');
           handleError(index, false);
         }*/
      };
      field_render = (
        <FormControl
          fullWidth
          size="small"
          required={field.required}
          disabled={field.disabled}
          error={errorField}
          onClick={() => {}}
        >
          <InputLabel id={"pms-select-label-" + field.id}>
            {field.name}
          </InputLabel>
          <Select
            labelId={"pms-select-label-" + field.id}
            id={"pms-select-" + field.id}
            inputProps={{ readOnly: field.readonly }}
            value={value}
            label={field.name}
            onChange={handleChangeField}
            onClose={handleCloseSelect}
          >
            {field.options.map((option, index) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      );
      break;
  }

  return (
    <Grid
      xs={grid.field.xs}
      sm={grid.field.sm}
      md={grid.field.md}
      lg={grid.field.lg}
      xl={grid.field.xl}
    >
      <Stack
        spacing={0}
        direction={field.control.bottom_position ? "column-reverse" : "column"}
      >
        {field.control.switch && (
          <FormGroup sx={{ pl: 1, pt: 1, pb: 1.0 }}>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={showField}
                  onChange={handleChangeShowField}
                />
              }
              label={field.control.name || field.name}
            />
          </FormGroup>
        )}
        {field.control.checkbox && (
          <FormGroup sx={{ pl: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={showField}
                  onChange={handleChangeShowField}
                />
              }
              label={field.control.name || field.name}
            />
          </FormGroup>
        )}
        {showField == !field.control.invert_mode && field_render}
      </Stack>
    </Grid>
  );
};

export default FormField;
