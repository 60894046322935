const DataFormColumns = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "type",
    headerName: "Tipo",
    width: 300,
  },
  {
    field: "line",
    headerName: "Linea",
    width: 100,
  },
  {
    field: "orders",
    headerName: "Ordenes",
    width: 200,
  },
  {
    field: "product",
    headerName: "Producto",
    width: 300,
  },
  {
    field: "inspections",
    headerName: "Inspecciones",
    type: "number",
    width: 140,
  },
  {
    field: "date_created",
    headerName: "Fecha Creación",
    width: 200,
  },
  {
    field: "state",
    headerName: "Estado",
    width: 100,
  },
];

export default DataFormColumns;
