const DataPlants = [
  {
    id: 1,
    name: "Planta 1",
    state_color: "red",
    active_lines: [
      { id: 1, name: "Linea 1", state_color: "green" },
      { id: 2, name: "Linea 2", state_color: "red" },
    ],
  },
];

export default DataPlants;
