/**
 * Component Create Ticket
 * @author: Luis Avello
 * @version 1.1.0
 * @updated 2023-11-24
 *
 */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { responseInfo, responseError } from "../utils/response_console";

const NewTicket = (props) => {
  const { forminstance_id } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    line: { name: "-" },
    order: { number: "-", product_fullname: "-" },
  });
  const [pallet, setPallet] = useState("");
  const [trazabilidad, setTrazabilidad] = useState("");

  const urlForm = "/api/react/v1/quality/data_form";

  useEffect(() => {
    if (forminstance_id > 0)
      axios
        .get(urlForm, {
          params: { forminstance_id: forminstance_id },
        })
        .then((response) => {
          responseInfo(response, true);
          console.log(response.data);
          setData(response.data);
          console.log("Linea: " + response.data.line.name);
          const section_pallet = response.data.sections.find(
            (json) => json["code"] === "PRODUCT_INFO"
          );
          const subsection_pallet = section_pallet.sections.find(
            (json) => json["code"] === "PRODUCT_INFO_EMPTY"
          );
          const field_pallet = subsection_pallet.fields.find(
            (json) => json["code"] === "PALLET"
          );
          setPallet(field_pallet.value);
          const field_trazabilidad = subsection_pallet.fields.find(
            (json) => json["code"] === "TRACEABILITY_CODE"
          );
          setTrazabilidad(field_trazabilidad.value);
        })
        .catch((error) => {
          responseError(error);
        });
  }, [forminstance_id]);

  const handleClickOpen = () => {
    setOpen(true);
    /*console.log("Ticket Opened");
    console.log("X1 forminstance_id: " + forminstance_id);
    if (forminstance_id > 0)
      axios
        .get(urlForm, {
          params: { forminstance_id: forminstance_id },
        })
        .then((response) => {
          responseInfo(response, true);
          console.log(response.data);
          setData(response.data);
          console.log("Linea: " + response.data.line.name);
        })
        .catch((error) => {
          responseError(error);
        });*/
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateTicket = () => {
    console.log("Create Ticket");
    setOpen(false);
  };

  return (
    <Container maxWidth="false">
      <Button variant="contained" onClick={handleClickOpen}>
        Nuevo Ticket
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Nuevo Ticket</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            id="line"
            label="Linea"
            variant="standard"
            value={data.line.name}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="order"
            label="Orden"
            variant="standard"
            value={data.order.number}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="pallet"
            label="Pallet"
            variant="standard"
            value={pallet}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="traza"
            label="Trazabilidad"
            variant="standard"
            value={trazabilidad}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="product"
            label="Producto"
            variant="standard"
            value={data.order.product_fullname}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="inspect"
            label="Inspección"
            variant="standard"
            defaultValue="0"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            id="inspect"
            label="Cantidad Inspeccionada"
            type="number"
            variant="standard"
            defaultValue="0"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            id="inspect"
            label="Cantidad No Conforme"
            type="number"
            variant="standard"
            defaultValue="0"
            autoFocus
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            fullWidth
            multiline
            rows={4}
            size="small"
            label="Observaciones"
            variant="outlined"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleCreateTicket} variant="contained">
            Crear
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default NewTicket;
