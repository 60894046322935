const DataFormEmpty = {
  formmain_id: 0,
  forminstance_id: 0,
  config: {
    grid: {
      section: {
        spacing: 1,
        columns: 12,
        xs: 12,
        sm: 12,
        md: 10,
        lg: 10,
        xl: 10,
      },
      field: {
        spacing: 2,
        columns: 12,
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
      },
    },
  },
  header: {
    name: "",
    company: "",
    form_code: "",
    revision: "",
    updated: "",
  },
  line: {
    id: 0,
    name: "",
  },
  order: {
    id: 0,
    number: "",
    product_name: "",
    product_code: "",
  },
  instance: 1,
  sections: [],
};

export default DataFormEmpty;
