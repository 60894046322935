import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Stack from "@mui/material/Stack";
import {
  DataGrid,
  GridToolbar,
  GridRowsProp,
  GridEventListener,
  esES,
} from "@mui/x-data-grid";

const TicketList = () => {
  // Your code for listing tickets goes here

  const rows = [
    {
      id: 1,
      date: "2024-03-01",
      line: "Linea 1",
      product: "Ron Abuelo 12 años",
      order: "50002",
      state: "Pendiente",
    },
    {
      id: 2,
      date: "2024-03-01",
      line: "Linea 2",
      product: "Ron Abuelo 7 años",
      order: "50003",
      state: "Pendiente",
    },
    {
      id: 3,
      date: "2024-03-01",
      line: "Linea 3",
      product: "Ron Abuelo 5 años",
      order: "50004",
      state: "Pendiente",
    },
    {
      id: 4,
      date: "2024-03-01",
      line: "Linea 4",
      product: "Ron Abuelo 3 años",
      order: "50005",
      state: "Pendiente",
    },
    {
      id: 5,
      date: "2024-03-01",
      line: "Linea 4",
      product: "Ron Abuelo 3 años",
      order: "50006",
      state: "Pendiente",
    },
    {
      id: 6,
      date: "2024-03-01",
      line: "Linea 4",
      product: "Ron Abuelo 3 años",
      order: "50007",
      state: "Pendiente",
    },
  ];

  const columnDef = [
    {
      field: "id",
      headerName: "Ticket ID",
      width: 100,
      type: "string",
    },
    {
      field: "date",
      headerName: "Fecha",
      width: 150,
      type: "string",
    },
    {
      field: "line",
      headerName: "Linea",
      width: 200,
      type: "string",
    },
    {
      field: "product",
      headerName: "Producto",
      width: 400,
      type: "string",
    },
    {
      field: "order",
      headerName: "Orden",
      width: 200,
      type: "string",
    },
    {
      field: "state",
      headerName: "Estado",
      width: 150,
      type: "string",
    },
  ];

  const handleForms = () => {
    console.log("Link to Forms");
    window.location.href = "/quality/dash";
  };

  const handleOnCellClick = (params) => {
    const row = params.row;
    console.log(
      `Cell id ${params.id} field ${params.field} value ${params.value} cod_fiscalia ${row.cod_fiscalia}`
    );
  };

  return (
    <Container maxWidth="false">
      <Grid container spacing={2} sx={{ pt: 1 }}>
        <Grid sm={8} md={10}>
          <Button
            size="small"
            variant="outlined"
            startIcon={<SummarizeIcon />}
            color="primary"
            onClick={handleForms}
          >
            Formularios por Linea
          </Button>
        </Grid>
        <Grid sm={12} md={12}>
          <Box>
            <DataGrid
              sx={{
                borderRadius: "8px",
              }}
              autoHeight
              density="compact"
              rowHeight={52}
              rows={rows}
              columns={columnDef}
              columnVisibilityModel={{}}
              hideFooter={false}
              disableColumnMenu={true}
              disableColumnSelector={true}
              disableRowSelectionOnClick={true}
              disableColumnFilter={true}
              disableDensitySelector={true}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              slots={{
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  csvOptions: {
                    fileName: `Tickets`,
                    delimiter: ";",
                    utf8WithBom: true,
                  },
                  printOptions: { disableToolbarButton: true },
                },
              }}
              onCellClick={handleOnCellClick}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TicketList;
