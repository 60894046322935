/**
 * Component FormList
 * @author: Luis Avello
 * @version 1.3.1
 * @updated 2023-09-07
 */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from "@mui/icons-material/Edit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import KeyIcon from "@mui/icons-material/Key";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import { red, purple, indigo } from "@mui/material/colors";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { responseInfo, responseError } from "../utils/response_console";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";

import DataForms from "./data_forms.js";

const FormList = (props) => {
  const { testing = false } = props;
  const [dateStart, setDateStart] = React.useState(dayjs());
  const [dateEnd, setDateEnd] = React.useState(dayjs());
  const [type, setType] = React.useState(0);
  const [types, setTypes] = React.useState([]);
  const [state, setState] = React.useState(0);
  const [states, setStates] = React.useState([]);
  const [line, setLine] = React.useState(0);
  const [lines, setLines] = useState([{ id: 0, name: "" }]);
  const [listForms, setListForms] = React.useState([]);

  const urlForms = "/api/react/v1/quality/forms";
  const urlLines = "/api/react/v1/quality/lines";
  const urlStates = "/api/react/v1/quality/states";
  const urlTypes = "/api/react/v1/quality/types";

  const color_edit = indigo[500];
  const color_pdf = red[600];
  const color_sign = purple[700];

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const handleChangeState = (event) => {
    setState(event.target.value);
  };

  const handleChangeLine = (event) => {
    setLine(event.target.value);
    console.log("Set line " + event.target.value);
  };

  const handleChangeDateStart = (newDatetime) => {
    setDateStart(newDatetime);
  };

  const handleChangeDateEnd = (newDatetime) => {
    setDateEnd(newDatetime);
  };

  const handleClickPdf = (formmain_id) => {
    console.log("Pdf " + formmain_id);
    window.location = "/quality/pdf?formmain_id=" + formmain_id;
  };

  const handleClickEdit = (formmain_id) => {
    console.log("Edit " + formmain_id);
    window.location = "/quality/form?formmain_id=" + formmain_id;
  };

  const handleClickSign = (formmain_id) => {
    console.log("Sign " + formmain_id);
  };

  useEffect(() => {
    if (testing) {
      console.log("Testing Mode");
      setListForms(DataForms);
      let dummy_lines = [
        { id: 1, name: "Linea 1" },
        { id: 2, name: "Linea 2" },
      ];
      let dummy_states = [
        { id: 1, name: "State 1" },
        { id: 2, name: "State 2" },
      ];
      let dummy_types = [
        { id: 1, name: "Type 1" },
        { id: 2, name: "Type 2" },
      ];
      setLines(dummy_lines);
      setStates(dummy_states);
      setTypes(dummy_types);
    } else {
      // Get States
      axios
        .get(urlStates, {
          params: {},
        })
        .then((response) => {
          responseInfo(response, true);
          setStates(response.data);
        })
        .catch((error) => {
          responseError(error);
        });
      // Get types
      axios
        .get(urlTypes, {
          params: {},
        })
        .then((response) => {
          responseInfo(response, true);
          setTypes(response.data);
        })
        .catch((error) => {
          responseError(error);
        });
      // Get lines
      axios
        .get(urlLines, {
          params: {},
        })
        .then((response) => {
          responseInfo(response, true);
          setLines(response.data);
        })
        .catch((error) => {
          responseError(error);
        });
      // Get forms
      axios
        .get(urlForms, {
          params: {
            line_id: line,
            date_start: dateStart.startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
            date_end: dateEnd.endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
            state_id: state,
            formtype_id: type,
          },
        })
        .then((response) => {
          responseInfo(response, true);
          setListForms(response.data);
        })
        .catch((error) => {
          responseError(error);
        });
    }
  }, [dateStart, dateEnd, line, state, type]);

  return (
    <Container maxWidth="true">
      <Grid container spacing={2}>
        <Grid sm={2} md={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Fecha Inicio"
              inputFormat="DD/MM/YYYY"
              value={dateStart}
              onChange={(newValue) => handleChangeDateStart(newValue)}
              renderInput={(params) => (
                <TextField fullWidth size="small" {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid sm={2} md={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Fecha Término"
              inputFormat="DD/MM/YYYY"
              value={dateEnd}
              onChange={(newValue) => handleChangeDateEnd(newValue)}
              renderInput={(params) => (
                <TextField fullWidth size="small" {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid sm={2} md={2}>
          <FormControl fullWidth size="small" onClick={() => {}}>
            <InputLabel id={"pms-select-label-type"}>
              Tipo Formulario
            </InputLabel>
            <Select
              labelId={"pms-select-label-type"}
              id={"pms-select-type"}
              value={type}
              onChange={handleChangeType}
              label="Tipo Formulario"
            >
              <MenuItem key={0} value={0}>
                Todos
              </MenuItem>
              {types.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid sm={2} md={2}>
          <FormControl fullWidth size="small" onClick={() => {}}>
            <InputLabel id={"pms-select-label-line"}>Linea</InputLabel>
            <Select
              labelId={"pms-select-label-line"}
              id={"pms-select-line"}
              value={line}
              onChange={handleChangeLine}
              label="Línea"
            >
              <MenuItem key={0} value={0}>
                Todas
              </MenuItem>
              {lines.map((line) => (
                <MenuItem key={line.id} value={line.id}>
                  {line.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid sm={2} md={2}>
          <FormControl fullWidth size="small" onClick={() => {}}>
            <InputLabel id={"pms-select-label-state"}>Estado</InputLabel>
            <Select
              labelId={"pms-select-label-state"}
              id={"pms-select-state"}
              value={state}
              onChange={handleChangeState}
              label="Estado"
            >
              <MenuItem key={0} value={0}>
                Todos
              </MenuItem>
              {states.map((state) => (
                <MenuItem key={state.id} value={state.id}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid sm={12} md={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Linea</TableCell>
                  <TableCell>Orden</TableCell>
                  <TableCell>Producto</TableCell>
                  <TableCell>Inspecciones</TableCell>
                  <TableCell>Fecha Creación</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listForms.map((form, index) => (
                  <TableRow key={form.id}>
                    <TableCell>{form.id}</TableCell>
                    <TableCell>{form.type}</TableCell>
                    <TableCell>{form.line}</TableCell>
                    <TableCell>{form.orders}</TableCell>
                    <TableCell>{form.product}</TableCell>
                    <TableCell>{form.inspections}</TableCell>
                    <TableCell>
                      {dayjs(form.date_created).format("DD/MM/YYYY HH:mm")}
                    </TableCell>
                    <TableCell>{form.state}</TableCell>
                    <TableCell>
                      <Tooltip title="Editar Formulario" arrow>
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleClickEdit(form.id)}
                        >
                          <EditIcon sx={{ color: color_edit }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ver PDF" arrow>
                        <IconButton
                          aria-label="pdf"
                          onClick={() => handleClickPdf(form.id)}
                        >
                          <PictureAsPdfIcon sx={{ color: color_pdf }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Firmar Formulario" arrow>
                        <IconButton
                          aria-label="sign"
                          onClick={() => handleClickSign(form.id)}
                        >
                          <KeyIcon sx={{ color: color_sign }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FormList;
