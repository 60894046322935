/**
 * Component FormSection
 * @author: Luis Avello
 * @version 1.9.0
 * @updated 2023-06-28
 */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormField from "./form_field";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { responseInfo, responseError } from "../utils/response_console";

const FormSection = (props) => {
  const { section, grid, handleError, index, validate, forminstance_id } =
    props;
  section.control || (section.control = {});
  const [showSection, setShowSection] = useState(
    !section.hide == !section.control.invert_mode && section.active
  );
  const [errorField, setErrorField] = useState(
    section.fields ? new Array(section.fields.length).fill(false) : []
  );
  const [errorSection, setErrorSection] = useState(
    section.sections ? new Array(section.sections.length).fill(false) : []
  );
  const urlValueSection = "/api/react/v1/quality/value_section";
  const urlSetValueSection = "/api/react/v1/quality/set_value_section";

  const handleChangeShowSection = (event) => {
    setShowSection(event.target.checked);
    setValueSection(event.target.checked);
  };

  const handleErrorField = (index_f, value) => {
    /*setErrorField((prevErrorField) => {
      return prevErrorField.map((item, i) => {
        return i === index_f ? value : item;
      });
    });*/
    console.log("handleErrorField index " + index_f + " value " + value);
    errorField[index_f] = value;
    setErrorField(errorField);
    handleError(
      index,
      errorField.includes(true) || errorSection.includes(true)
    );
  };

  const handleErrorSection = (index_s, value) => {
    errorSection[index_s] = value;
    setErrorSection(errorSection);
    handleError(
      index,
      errorField.includes(true) || errorSection.includes(true)
    );
  };

  useEffect(() => {
    if (
      (section.control.switch || section.control.checkbox) &&
      forminstance_id > 0
    )
      axios
        .get(urlValueSection, {
          params: {
            forminstance_id: forminstance_id,
            formsection_id: section.id,
          },
        })
        .then((response) => {
          responseInfo(response, true);
          setShowSection(response.data.value == "true");
        })
        .catch((error) => {
          responseError(error);
        });
  }, []);

  const setValueSection = (new_value) => {
    axios
      .get(urlSetValueSection, {
        params: {
          forminstance_id: forminstance_id,
          formsection_id: section.id,
          value: new_value,
        },
      })
      .then((response) => {
        responseInfo(response, true);
      })
      .catch((error) => {
        responseError(error);
      });
  };

  return (
    <Box>
      {section.control.switch && (
        <FormGroup sx={{ pl: 1 }}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={showSection}
                onChange={handleChangeShowSection}
              />
            }
            label={section.control.name || section.name}
          />
        </FormGroup>
      )}
      {section.control.checkbox && (
        <FormGroup sx={{ pl: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showSection}
                onChange={handleChangeShowSection}
                size="small"
              />
            }
            label={section.control.name || section.name}
          />
        </FormGroup>
      )}

      {section.show_name && (
        <Typography variant="button">{section.name}</Typography>
      )}
      {showSection == !section.control.invert_mode && (
        <Paper
          elevation={1}
          sx={{
            p: 0.5,
            mt: 0.5,
            mb: 0.5,
            ml: section.control.checkbox || section.control.switch ? 2.5 : 0,
          }}
        >
          <Grid
            container
            xs={grid.section.xs}
            sm={grid.section.sm}
            md={grid.section.md}
            lg={grid.section.lg}
            xl={grid.section.xl}
          >
            {section.fields &&
              section.fields.map((field, index) => (
                <FormField
                  key={field.id}
                  field={field}
                  grid={grid}
                  index={index}
                  handleError={handleErrorField}
                  validate={field.required ? validate : 0}
                  forminstance_id={forminstance_id}
                />
              ))}
          </Grid>

          {section.sections &&
            section.sections.map((subsection, index) => (
              <FormSection
                key={subsection.id}
                section={subsection}
                grid={grid}
                index={index}
                handleError={handleErrorSection}
                validate={validate}
                forminstance_id={forminstance_id}
              />
            ))}
        </Paper>
      )}
    </Box>
  );
};

export default FormSection;
