/**
 * Component FormLoginDialog
 * @author: Luis Avello
 * @version 1.1.0
 * @updated 2023-09-26
 */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { responseInfo, responseError } from "../../utils/response_console";

const FormLoginDialog = (props) => {
  const {
    openDialog,
    handleOpenDialog,
    handleLoginResult,
    handleNameInspector,
  } = props;
  const [listInspector, setListInspector] = useState([]);
  const [pin, setPin] = useState("");
  const [selected, setSelected] = useState(0);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageSnack, setMessageSnack] = React.useState("");
  const urlInspectors = "/api/react/v1/quality/inspectors";
  const urlLogin = "/api/react/v1/quality/login";

  useEffect(() => {
    axios
      .get(urlInspectors, {
        params: {},
      })
      .then((response) => {
        responseInfo(response, true);
        setListInspector(response.data);
      })
      .catch((error) => {
        responseError(error);
      });
  }, []);

  const handleChange = (event) => {
    console.log("onChange Select: " + event.target.value);
    setSelected(event.target.value);
  };

  const handleCloseDialog = () => {
    console.log("Close Dialog Login");
    //setOpenDialog(false);
  };

  const handleChangeField = (event) => {
    console.log("onChangeField: " + event.target.value);
    setPin(event.target.value);
  };

  const handleLogin = () => {
    console.log("Login");

    axios
      .get(urlLogin, {
        params: { inspector_id: selected, pin: pin },
      })
      .then((response) => {
        responseInfo(response, true);
        handleLoginResult(response.data.result);
        handleNameInspector(response.data.name);
        if (response.data.result) {
          //setMessageSnack("Bienvenido");
          //setOpenSnack(true);
          handleOpenDialog(false);
        } else {
          console.log("Error pin");
          setMessageSnack("ERROR: PIN Incorrecto");
          setOpenSnack(true);
        }
      })
      .catch((error) => {
        responseError(error);
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Ingresar Inspector</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description"></DialogContentText>
        <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="inspector-select-label">Inspector</InputLabel>
            <Select
              labelId="inspector-select-label"
              id="inspector-select"
              value={selected}
              label="Inspector"
              onChange={(event) => handleChange(event)}
            >
              {listInspector.map((inspector) => (
                <MenuItem key={inspector.id} value={inspector.id}>
                  {inspector.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="outlined-password-input"
            label="PIN"
            type="password"
            autoComplete="current-password"
            onChange={handleChangeField}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleLogin} autoFocus>
          Ingresar
        </Button>
        <Button onClick={() => handleOpenDialog(false)}>Cancelar</Button>
      </DialogActions>

      <Snackbar
        open={openSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        onClose={handleCloseSnack}
      >
        <Alert
          variant="filled"
          onClose={handleCloseSnack}
          severity="info"
          sx={{ width: "100%" }}
        >
          {messageSnack}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default FormLoginDialog;
