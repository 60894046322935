/**
 * Component FormHeader
 * @author: Luis Avello
 * @version 1.6.0
 * @updated 2023-08-08
 */
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { responseInfo, responseError } from "../utils/response_console";

const FormHeader = (props) => {
  const { header, line, code, order } = props;
  const [selected, setSelected] = useState(line.id);
  const [lines, setLines] = useState([{ id: 0, order_id: 0, name: "" }]);
  const urlLines = "/api/react/v1/quality/lines";

  useEffect(() => {
    if (line.id > 0)
      axios
        .get(urlLines, {
          params: {},
        })
        .then((response) => {
          responseInfo(response, true);
          setLines(response.data);
          setSelected(order.id);
        })
        .catch((error) => {
          responseError(error);
        });
  }, [line]);

  const handleChange = (event) => {
    console.log("onChange Select: " + event.target.value);
    setSelected(event.target.value);
    window.location =
      "/quality/form?code=" + code + "&order_id=" + event.target.value;
  };

  const handleBack = (event) => {
    console.log("Back to dash");
    //window.location = "/quality/dash";
    window.history.back();
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Paper elevation={3} sx={{ p: 1 }}>
        <Typography variant="h6" align="center">
          {header.name}
        </Typography>
        <Stack direction="row" justifyContent="space-around" spacing={7}>
          <Tooltip title="Volver">
            <IconButton
              aria-label="back"
              size="small"
              onClick={handleBack}
              sx={{ border: 1, width: 35, height: 35 }}
            >
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Typography variant="subtitle1">{`${header.company}`}</Typography>
          <Typography variant="subtitle1">{`${header.form_code} | ${header.revision} | ${header.updated}`}</Typography>

          <Box sx={{ minWidth: 100 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="line-select-label">Linea</InputLabel>
              <Select
                labelId="line-select-label"
                id="line-select"
                value={selected}
                label="Linea"
                onChange={(event) => handleChange(event)}
              >
                {lines.map((line) => (
                  <MenuItem key={line.id} value={line.order_id}>
                    {line.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};

export default FormHeader;
