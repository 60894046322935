/**
 * Component Quality Line Item
 * @author: Luis Avello
 * @version 1.7.0
 * @updated 2023-06-07
 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Grid from "@mui/material/Unstable_Grid2";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import CircleIcon from "@mui/icons-material/Circle";
import StraightenRoundedIcon from "@mui/icons-material/StraightenRounded";
import LiquorIcon from "@mui/icons-material/Liquor";
import Tooltip from "@mui/material/Tooltip";

import dayjs from "dayjs";
import { responseInfo, responseError } from "../utils/response_console";

import DataIndicator from "./data_indicator.js";
import DataOrders from "./data_orders.js";

const QualityLineItem = (props) => {
  const { order, line, update, test } = props;

  const urlIndicator = "/api/v2/dash/indicator";
  const urlOrders = "/api/react/v1/quality/orders";

  const [indicator, setIndicator] = useState({
    oee: 0,
    state: { name: "", color: "red" },
    shift: { name: "" },
    product: { name: "", order: "" },
    operator: { name: "" },
  });

  const [orders, setOrders] = useState({
    running: [],
    completed: [],
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const handleClickStep = (index) => {
    console.log("Click Step order");
    setActiveStep(index);
  };

  let orders_all = [...orders.completed, ...orders.running];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_menu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    console.log("Cierre Menu");
  };

  const handleClickMenuItem = (event) => {
    setAnchorEl(null);
    let newTypeForm = event.target.getAttribute("value");
    console.log(newTypeForm);

    switch (newTypeForm) {
      case "new_product":
        window.location = "/quality/new_form_product?line_id=" + line.id;
        break;
      case "new_line":
        window.location = "/quality/new_form_line?line_id=" + line.id;
        break;
    }
    //setTypeChart(newTypeChart);
  };

  const handleForm = (order_id, code) => {
    console.log("Formulario " + code);
    window.location =
      "/quality/form?code=" +
      code +
      "&line_id=" +
      line.id +
      "&order_id=" +
      order_id;
  };

  const handleFormProduct = (order_id) => {
    console.log("Formulario Producto");
    window.location =
      "/quality/form?code=FINISHED_PRODUCT&line_id=" +
      line.id +
      "&order_id=" +
      order_id;
  };

  const handleFormLine = (order_id) => {
    console.log("Formulario Linea");
    window.location =
      "/quality/form?code=LINE&line_id=" + line.id + "&order_id=" + order_id;
  };

  const handleNewFormProduct = (order_id) => {
    console.log("Nuevo Formulario Producto");
    window.location =
      "/quality/new_form_product?line_id=" + line.id + "&order_id=" + order_id;
  };
  const handleNewFormLine = (order_id) => {
    console.log("Nuevo Formulario Linea");
    window.location =
      "/quality/new_form_line?line_id=" + line.id + "&order_id=" + order_id;
  };

  const handleEditFormProduct = (order_id) => {
    console.log("Editar Formulario Producto");
  };
  const handleEditFormLine = (order_id) => {
    console.log("Editar Formulario Linea");
  };

  let color_state = { bgcolor: "#e53935", color: "#ffffff" };
  switch (indicator.state.color) {
    case "red":
      color_state = { bgcolor: "#e53935", color: "#ffffff" };
      break;
    case "green":
      color_state = { bgcolor: "#00cc00", color: "#ffffff" };
      break;
  }

  useEffect(() => {
    if (!test) {
      axios
        .get(urlIndicator, {
          params: { model: "line", id: line.id },
        })
        .then((response) => {
          responseInfo(response, true);
          setIndicator(response.data);
        })
        .catch((error) => {
          responseError(error);
        });

      axios
        .get(urlOrders, {
          params: { line_id: line.id },
        })
        .then((response) => {
          responseInfo(response, true);
          setOrders(response.data);
          setActiveStep(
            response.data.running.length + response.data.completed.length - 1
          );
        })
        .catch((error) => {
          responseError(error);
        });
    } else {
      console.log("-> QualityLineItem Test ON !");
      setIndicator(DataIndicator);
      setOrders(DataOrders);
      setActiveStep(
        DataOrders.running.length + DataOrders.completed.length - 1
      );
    }
  }, [line.id, update]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Card sx={{ boxShadow: 4 }}>
      <CardContent>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography gutterBottom variant="h6" component="div" sx={{ mt: 1 }}>
            {line.name}
          </Typography>
          <Chip label={indicator.state.name} size="small" sx={color_state} />
        </Stack>
        <Stepper
          nonLinear
          activeStep={activeStep}
          orientation="vertical"
          connector={<div />}
        >
          {orders_all.map((order, index) => (
            <Step key={order.number}>
              <StepLabel
                onClick={() => handleClickStep(index)}
                sx={{ textTransform: "uppercase" }}
                optional={
                  <Typography variant="caption">
                    {`${order.product_code} ${order.product_name}`}
                  </Typography>
                }
              >
                Orden {order.number}
                {order.show_buttons_form ? (
                  <Tooltip
                    title={
                      order.form_line
                        ? "Editar Formulario Línea"
                        : "Nuevo Formulario Línea"
                    }
                  >
                    <Button
                      size="small"
                      variant={order.form_line ? "outlined" : "contained"}
                      startIcon={order.form_line ? <EditIcon /> : <AddIcon />}
                      endIcon={<StraightenRoundedIcon />}
                      color="primary"
                      onClick={() => handleForm(order.id, "LINE")}
                      sx={{ ml: 1 }}
                    ></Button>
                  </Tooltip>
                ) : null}
                {order.show_buttons_form ? (
                  <Tooltip
                    title={
                      order.form_product
                        ? "Editar Formulario Producto Terminado"
                        : "Nuevo Formulario Producto Terminado"
                    }
                  >
                    <Button
                      size="small"
                      variant={order.form_product ? "outlined" : "contained"}
                      startIcon={
                        order.form_product ? <EditIcon /> : <AddIcon />
                      }
                      endIcon={<LiquorIcon />}
                      color="primary"
                      onClick={() => handleForm(order.id, "FINISHED_PRODUCT")}
                      sx={{ ml: 1 }}
                    ></Button>
                  </Tooltip>
                ) : null}
              </StepLabel>
              <StepContent
                TransitionProps={{ unmountOnExit: false }}
              ></StepContent>
            </Step>
          ))}
        </Stepper>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

export default QualityLineItem;
