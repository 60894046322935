/**
 * Component FormView
 * @author: Luis Avello
 * @version 1.1.0
 * @updated 2023-07-12
 */
import React, { useEffect, useState } from "react";
import FormView from "./form_view";
import DataFormProduct from "./data_form_product";
import axios from "axios";

import { responseInfo, responseError } from "../utils/response_console";

const FormProduct = (props) => {
  const { line_id, order_id } = props;
  const urlForm = "/api/react/v1/quality/data_form";
  const [data, setData] = useState(DataFormProduct);

  useEffect(() => {
    if (line_id > 0)
      axios
        .get(urlForm, {
          params: { order_id: order_id, code: "FINISHED_PRODUCT" },
        })
        .then((response) => {
          responseInfo(response, true);
          setData(response.data);
        })
        .catch((error) => {
          responseError(error);
        });
  }, []);

  return <FormView data={data} line_id={line_id} order_id={order_id} />;
};

export default FormProduct;
