/**
 * Component FormView
 * @author: Luis Avello
 * @version 1.7.0
 * @updated 2023-06-27
 */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import FormHeader from "./form_header";
import FormTab from "./form_tab";

const FormView = (props) => {
  const { data } = props;
  return (
    <Container maxWidth="true">
      <Grid container spacing={1} justifyContent="center">
        <Grid
          xs={data.config.grid.section.xs}
          sm={data.config.grid.section.sm}
          md={data.config.grid.section.md}
          lg={data.config.grid.section.lg}
          xl={data.config.grid.section.xl}
        >
          <FormHeader
            header={data.header}
            line={data.line}
            code={data.code}
            order={data.order}
          />
        </Grid>
        <Grid
          xs={data.config.grid.section.xs}
          sm={data.config.grid.section.sm}
          md={data.config.grid.section.md}
          lg={data.config.grid.section.lg}
          xl={data.config.grid.section.xl}
        >
          <FormTab data={data} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default FormView;
