/**
 * Component DatePickerField
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2023-08-11
 */
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { indigo } from "@mui/material/colors";
import dayjs from "dayjs";
import "dayjs/locale/es";

const DatePickerField = (props) => {
  const { name, date, handleChange, disableButtons = false } = props;

  const color_button = indigo[500];

  useEffect(() => {
    dayjs.locale("es");
    console.log("Seteo ES");
  }, []);

  return (
    <Stack direction="row" spacing={0}>
      {!disableButtons ? (
        <Tooltip title="Día Anterior">
          <IconButton
            aria-label="Prev"
            onClick={() => {
              handleChange(date.subtract(1, "day"));
            }}
          >
            <ChevronLeftIcon sx={{ color: color_button }} />
          </IconButton>
        </Tooltip>
      ) : null}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={name}
          inputFormat="DD/MM/YYYY"
          value={date}
          onChange={(newValue) => handleChange(newValue)}
          renderInput={(params) => (
            <TextField size="small" fullWidth {...params} />
          )}
        />
      </LocalizationProvider>
      {!disableButtons ? (
        <Tooltip title="Día Siguiente">
          <IconButton
            aria-label="Next"
            onClick={() => {
              handleChange(date.add(1, "day"));
            }}
          >
            <ChevronRightIcon sx={{ color: color_button }} />
          </IconButton>
        </Tooltip>
      ) : null}
    </Stack>
  );
};

export default DatePickerField;
