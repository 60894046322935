/**
 * Component function AllForms
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2023-09-26
 */
import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import SelectField from "../../fields/select_field";
import DatePickerField from "../../fields/date_picker_field";

import "@webdatarocks/webdatarocks/webdatarocks.min.css";
import * as WebDataRocksReact from "@webdatarocks/react-webdatarocks";
import WebDataRocksHighcharts from "./webdatarocks.highcharts.js";

import { responseInfo, responseError } from "../../utils/response_console";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/es";

function AllForms() {
  const [dateStart, setDateStart] = React.useState(dayjs());
  const [dateEnd, setDateEnd] = React.useState(dayjs());
  const [type, setType] = React.useState(0);
  const [types, setTypes] = React.useState([]);
  const [line, setLine] = React.useState(0);
  const [lines, setLines] = useState([]);
  const [forms, setForms] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const pivotRef = useRef(null);

  const urlAllForms = "/api/react/v1/quality/all_forms";
  const urlAllColumns = "/api/react/v1/quality/all_columns";
  const urlLines = "/api/react/v1/quality/lines";
  const urlTypes = "/api/react/v1/quality/types";

  const slice = {};

  const report = {
    dataSource: {
      dataSourceType: "json",
      data: [],
    },
    slice: slice,
    options: {
      grid: {
        showHeaders: false,
      },
    },
  };

  const global = {
    localization: "https://cdn.webdatarocks.com/loc/es.json",
    slice: slice,
  };

  const createChart = () => {
    console.log("createChart");
    pivotRef.current.webdatarocks.highcharts.getData(
      {
        type: "column",
      },
      function (data) {
        Highcharts.chart("highchartsContainer", data);
      },
      function (data) {
        Highcharts.chart("highchartsContainer", data);
      }
    );
  };

  useEffect(() => {
    pivotRef.current.webdatarocks.setReport({
      dataSource: {
        dataSourceType: "json",
        data: forms,
      },
      slice: slice,
    });
  }, [forms]);

  useEffect(() => {
    // Get columns
    axios
      .get(urlAllColumns, {
        params: { formtype_id: type },
      })
      .then((response) => {
        responseInfo(response, true);
        setColumns(response.data);
      })
      .catch((error) => {
        responseError(error);
      });
  }, [type]);

  useEffect(() => {
    // Get types
    axios
      .get(urlTypes, {
        params: {},
      })
      .then((response) => {
        responseInfo(response, true);
        setTypes(response.data);
      })
      .catch((error) => {
        responseError(error);
      });
    // Get lines
    axios
      .get(urlLines, {
        params: {},
      })
      .then((response) => {
        responseInfo(response, true);
        setLines(response.data);
      })
      .catch((error) => {
        responseError(error);
      });

    // Get forms
    axios
      .get(urlAllForms, {
        params: {
          line_id: line,
          date_start: dateStart.startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
          date_end: dateEnd.endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
          formtype_id: type,
        },
      })
      .then((response) => {
        responseInfo(response, true);
        setForms(response.data);
      })
      .catch((error) => {
        responseError(error);
      });
  }, [dateStart, dateEnd, line, type]);

  const columns_old = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "type",
      headerName: "Tipo",
      width: 300,
    },
    {
      field: "line",
      headerName: "Linea",
      width: 100,
    },
  ];

  return (
    <Container maxWidth="true" sx={{ mt: 2 }}>
      <Grid container spacing={1}>
        <Grid sm={2} md={2}>
          <DatePickerField
            name="Fecha Inicio"
            date={dateStart}
            handleChange={(newDatetime) => setDateStart(newDatetime)}
            disableButtons={false}
          />
        </Grid>
        <Grid sm={2} md={2}>
          <DatePickerField
            name="Fecha Término"
            date={dateEnd}
            handleChange={(newDatetime) => setDateEnd(newDatetime)}
            disableButtons={false}
          />
        </Grid>
        <Grid sm={2} md={2}>
          <SelectField
            name="Tipo Formulario"
            value={type}
            data={types}
            handleChange={(event) => setType(event.target.value)}
            disableOptionAll={true}
          />
        </Grid>
        <Grid sm={2} md={2}>
          <SelectField
            name="Linea"
            value={line}
            data={lines}
            all="Todas"
            handleChange={(event) => setLine(event.target.value)}
          />
        </Grid>
        <Grid sm={2} md={2}></Grid>

        <Grid sm={12} md={12} xs={12}>
          <DataGrid
            rows={forms}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5]}
            slots={{ toolbar: GridToolbar }}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            disableRowSelectionOnClick
          />
        </Grid>

        <Grid sm={6} md={6} xs={6}>
          <WebDataRocksReact.Pivot
            ref={pivotRef}
            toolbar={true}
            report={report}
            global={global}
            reportcomplete={() => {
              pivotRef.current.webdatarocks.off("reportcomplete");
              console.log("Se carga reporte");
              createChart();
            }}
          />
        </Grid>
        <Grid sm={3} md={3} xs={3}>
          <div
            id={"highchartsContainer"}
            style={{ width: 400, height: 400 }}
          ></div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AllForms;
