/**
 * Component FormGenerate
 * @author: Luis Avello
 * @version 1.2.0
 * @updated 2023-08-08
 */
import React, { useEffect, useState } from "react";
import FormView from "./form_view";
import DataFormEmpty from "./data_form_empty";
import axios from "axios";

import { responseInfo, responseError } from "../utils/response_console";

const FormGenerate = (props) => {
  const { order_id, code, instance, formmain_id } = props;
  const urlForm = "/api/react/v1/quality/data_form";
  const [data, setData] = useState(DataFormEmpty);

  useEffect(() => {
    axios
      .get(urlForm, {
        params: {
          order_id: order_id,
          code: code,
          instance: instance,
          formmain_id: formmain_id,
        },
      })
      .then((response) => {
        responseInfo(response, true);
        setData(response.data);
      })
      .catch((error) => {
        responseError(error);
      });
  }, []);

  return <FormView data={data} />;
};

export default FormGenerate;
