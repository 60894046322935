const DataForms = [
  {
    id: 58,
    type: "Formulario de Inspección de Línea",
    line: "Línea 1",
    state: "En Edición",
    date_created: "2023-07-18T03:45:52.000-04:00",
    orders: "4412 ",
    product: "R. ABUELO 12 AÑOS 1000ML",
    inspections: 1,
  },
  {
    id: 59,
    type: "Formulario de Inspección de Producto Terminado",
    line: "Línea 1",
    state: "En Edición",
    date_created: "2023-07-18T17:21:17.000-04:00",
    orders: "4412 ",
    product: "R. ABUELO 12 AÑOS 1000ML",
    inspections: 1,
  },
  {
    id: 60,
    type: "Formulario de Inspección de Producto Terminado",
    line: "Línea 1",
    state: "En Edición",
    date_created: "2023-07-20T21:14:24.000-04:00",
    orders: "4411 ",
    product: "R. ABUELO 12 AÑOS 1000ML",
    inspections: 1,
  },
  {
    id: 61,
    type: "Formulario de Inspección de Producto Terminado",
    line: "Línea 1",
    state: "En Edición",
    date_created: "2023-08-07T04:38:09.000-04:00",
    orders: "4407 ",
    product: "R. ABUELO 12 AÑOS 1000ML",
    inspections: 1,
  },
];

export default DataForms;
