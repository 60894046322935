/**
 * Component PdfProduct
 * @author: Luis Avello
 * @version 1.2.0
 * @updated 2023-10-05
 */
import * as React from "react";
import jsPDF, { AcroFormCheckBox } from "jspdf";
import logo from "../imgs/logo_varela.png";
import { autoTable } from "jspdf-autotable";
import dataTest from "./data_pdf_product.json";
import { gl } from "date-fns/locale";

function addCheckBox(field, pdf, option, fontSize) {
  field.readOnly = true;
  field.fontSize = fontSize;
  field.appearanceState = option;
  pdf.addField(field);
}

// Function to concat elements of an array if they are not empty
const concatElements = (array) => {
  let result = "";
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== "") {
      result += array[i] + " ";
    }
  }
  return result;
};

// Function to remove duplicates from an array, and return string separated by commas
const removeDuplicates = (array) => {
  let uniqueArray = [];
  array.forEach((element) => {
    if (!uniqueArray.includes(element)) {
      uniqueArray.push(element);
    }
  });
  return uniqueArray.join(", ");
};

function PdfProduct(data) {
  let date = new Date().toLocaleDateString("es-PA");
  const pdf = new jsPDF("p", "mm", "letter");

  pdf.addImage(logo, "PNG", 15, 8, 14, 14);
  pdf.setFontSize(11);
  pdf.setFont("helvetica", "bold");

  pdf.text(
    ["INSPECCIÓN DE PRODUCTO TERMINADO", "Varela Hermanos S.A."],
    107.95,
    11,
    { align: "center" }
  );
  pdf.text(["Fecha:", "Producto:", "Tipo de Inspección:"], 15, 28, {
    align: "left",
  });
  pdf.text(["Presentación:", "Línea:"], 102, 28, { align: "right" });
  pdf.text(["Inspector:", "Material en SAP:"], 160, 28, { align: "right" });
  pdf.text(["INFORMACIÓN DEL PRODUCTO"], 107.95, 60, { align: "center" });

  pdf.setFontSize(7);
  pdf.text(
    [data.header.form_code, data.header.revision, data.header.updated],
    202,
    12,
    {
      align: "right",
    }
  );

  pdf.setFont(undefined, "normal").setFontSize(11);
  pdf.text(
    [
      data.body.GENERAL.DATE_FORM.VALUES[0],
      data.body.GENERAL.PRODUCT_NAME.VALUES[0],
    ],
    35,
    28,
    {
      align: "left",
    }
  );
  pdf.text(
    [
      data.body.GENERAL.PRODUCT_PRESENTATION.VALUES[0],
      data.body.GENERAL.LINE.VALUES[0],
    ],
    104,
    28,
    { align: "left" }
  );
  pdf.text(
    [
      data.body.GENERAL.INSPECTOR.VALUES[0],
      removeDuplicates(data.body.GENERAL.MATERIAL_SAP.VALUES),
    ],
    162,
    28,
    {
      align: "left",
    }
  );
  //pdf.text(inspector + "\n" + material_sap, 162, 28, {
  //  align: "left",
  //});

  // Opciones de tipo de inspección
  pdf.setFontSize(9);
  pdf.text(["Producción"], 58, 37, { align: "left" });
  pdf.text(["Retrabajo (producto en proceso)"], 58, 42, { align: "left" });
  pdf.text(["Re-inspección por detención"], 58, 47, { align: "left" });
  pdf.text(["Transferencia de inventario"], 58, 52, { align: "left" });
  pdf.setFontSize(11);

  const tipo_inspeccion = data.body.GENERAL.TYPE_INSPECTION.VALUES[0];

  let produccion = new AcroFormCheckBox();
  produccion.Rect = [53, 34, 4, 4];
  let retrabajo = new AcroFormCheckBox();
  retrabajo.Rect = [53, 39, 4, 4];
  let reinspeccion = new AcroFormCheckBox();
  reinspeccion.Rect = [53, 44, 4, 4];
  let transferencia = new AcroFormCheckBox();
  transferencia.Rect = [53, 49, 4, 4];
  if (tipo_inspeccion === "PRODUCTION") {
    addCheckBox(produccion, pdf, "On", 11);
  } else {
    addCheckBox(produccion, pdf, "Off", 11);
  }
  if (tipo_inspeccion === "REWORK") {
    addCheckBox(retrabajo, pdf, "On", 11);
  } else {
    addCheckBox(retrabajo, pdf, "Off", 11);
  }
  if (tipo_inspeccion === "REINSPECT") {
    addCheckBox(reinspeccion, pdf, "On", 11);
  } else {
    addCheckBox(reinspeccion, pdf, "Off", 11);
  }
  if (tipo_inspeccion === "TRANSFER") {
    addCheckBox(transferencia, pdf, "On", 11);
  } else {
    addCheckBox(transferencia, pdf, "Off", 11);
  }

  const num_inspections =
    data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY.HOUR_INSPECTION.VALUES.length;

  const hour_inspection =
    data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY.HOUR_INSPECTION.VALUES;
  const pallet = data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY.PALLET.VALUES;
  const hour_pallet = [];
  // Add hour_inspection and pallet to hour_pallet array
  for (let i = 0; i < num_inspections; i++) {
    hour_pallet.push(hour_inspection[i] + " " + pallet[i]);
  }

  const print_on_box_date =
    data.body.PRODUCT_INFO.PRODUCT_INFO_PRINT_BOX.DATE_PRINT_ON_BOX.VALUES;
  const print_on_box_hour =
    data.body.PRODUCT_INFO.PRODUCT_INFO_PRINT_BOX.HOUR_PRINT_ON_BOX.VALUES;
  const print_on_box_date_hour = [];
  // Add print_on_box_date and print_on_box_hour to print_on_box_date_hour array
  for (let i = 0; i < num_inspections; i++) {
    print_on_box_date_hour.push(
      print_on_box_date[i] + " " + print_on_box_hour[i]
    );
  }

  const imported_print_on_box =
    data.body.PRODUCT_INFO.PRODUCT_INFO_PRINT_BOX.IMPORTED_PRINT_ON_BOX.VALUES;
  const destination_print_on_box =
    data.body.PRODUCT_INFO.PRODUCT_INFO_PRINT_BOX.DESTINATION_PRINT_ON_BOX
      .VALUES;
  const number_of_boxes_inspected =
    data.body.PRODUCT_INFO.PRODUCT_INFO_PRINT_BOX.NUMBER_OF_BOXES_INSPECTED
      .VALUES;
  const imported_destination_print_on_box = [];
  // Add imported_print_on_box and destination_print_on_box to imported_destination_print_on_box array
  for (let i = 0; i < num_inspections; i++) {
    imported_destination_print_on_box.push(
      imported_print_on_box[i] +
        " " +
        destination_print_on_box[i] +
        " " +
        number_of_boxes_inspected[i]
    );
  }

  pdf.autoTable({
    head: [
      [
        "",
        "1era Inspección",
        "2da Inspección",
        "3era Inspección",
        "4ta Inspección",
        "5ta Inspección",
      ],
    ],
    body: [
      ["Hora-Pallet", ...hour_pallet],
      [
        "Código de Trazabilidad",
        ...data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY.TRACEABILITY_CODE.VALUES,
      ],
      [
        "C. de Barra del Producto",
        ...data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY.PRODUCT_BARCODE.VALUES,
      ],
      [
        "C. de Barra (cajetilla)",
        ...data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY.BOX_BARCODE.VALUES,
      ],
      [
        "C. de Barra (corrugado)",
        ...data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY.CORRUGATED_BARCODE.VALUES,
      ],
      [
        "Registro Sanitario",
        ...data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY.HEALTH_REGISTER.VALUES,
      ],
      [
        {
          content: ["Impresión en la Caja"],
          rowSpan: 2,
          styles: { valign: "center" },
        },
        ...imported_destination_print_on_box,
      ],
      [...print_on_box_date_hour],
      [
        "Pais Destino",
        ...data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY_2.DESTINATION_COUNTRY
          .VALUES,
      ],
      [
        "Leyenda del destino",
        ...data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY_2.DESTINATION_LEGEND
          .VALUES_ALT,
      ],
      [
        "Apariencia del producto",
        ...data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY_2.PRODUCT_APPEARANCE
          .VALUES_ALT,
      ],
      [
        "Persona Informada",
        ...data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY_2.INFORMED_PERSON.VALUES,
      ],
      [
        "Desgarre de Papel",
        ...data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY_2.PAPER_TEAR.VALUES_ALT,
      ],
      [
        "Estado de Inspección",
        ...data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY_2.INSPECTION_STATUS
          .VALUES_ALT,
      ],
      [
        "Motivo del Estado de Inspección",
        ...data.body.PRODUCT_INFO.PRODUCT_INFO_EMPTY_2.REASON_INSPECTION_STATUS
          .VALUES,
      ],
    ],
    startY: 62,
    styles: { fontSize: 8, cellPadding: 1, minCellHeight: 8 },
    columnStyles: {
      0: { fillColor: [221, 221, 221], textColor: [0, 0, 0], minCellWidth: 20 },
    },
    theme: "grid",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  const labels_front = [];
  const labels_front_supplier =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
      .ADDITIONAL_SUPPLY_INFO_LABELS_FRONT.SUPPLIER.VALUES;
  const labels_front_date_production =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
      .ADDITIONAL_SUPPLY_INFO_LABELS_FRONT.DATE_PRODUCTION.VALUES;
  for (let i = 0; i < num_inspections; i++) {
    labels_front.push(
      labels_front_supplier[i] + " " + labels_front_date_production[i]
    );
  }

  const labels_recipebook = [];
  const labels_recipebook_supplier =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
      .ADDITIONAL_SUPPLY_INFO_LABELS_RECIPEBOOK.SUPPLIER.VALUES;
  const labels_recipebook_date_production =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
      .ADDITIONAL_SUPPLY_INFO_LABELS_RECIPEBOOK.DATE_PRODUCTION.VALUES;
  for (let i = 0; i < num_inspections; i++) {
    labels_recipebook.push(
      labels_recipebook_supplier[i] + " " + labels_recipebook_date_production[i]
    );
  }

  const labels_neck = [];
  const labels_neck_supplier =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
      .ADDITIONAL_SUPPLY_INFO_LABELS_NECK.SUPPLIER.VALUES;
  const labels_neck_date_production =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
      .ADDITIONAL_SUPPLY_INFO_LABELS_NECK.DATE_PRODUCTION.VALUES;
  for (let i = 0; i < num_inspections; i++) {
    labels_neck.push(
      labels_neck_supplier[i] + " " + labels_neck_date_production[i]
    );
  }

  const labels_shoulder = [];
  const labels_shoulder_supplier =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
      .ADDITIONAL_SUPPLY_INFO_LABELS_SHOULDER.SUPPLIER.VALUES;
  const labels_shoulder_date_production =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
      .ADDITIONAL_SUPPLY_INFO_LABELS_SHOULDER.DATE_PRODUCTION.VALUES;
  for (let i = 0; i < num_inspections; i++) {
    labels_shoulder.push(
      labels_shoulder_supplier[i] + " " + labels_shoulder_date_production[i]
    );
  }

  const labels_oval = [];
  const labels_oval_supplier =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
      .ADDITIONAL_SUPPLY_INFO_LABELS_OVAL.SUPPLIER.VALUES;
  const labels_oval_date_production =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
      .ADDITIONAL_SUPPLY_INFO_LABELS_OVAL.DATE_PRODUCTION.VALUES;
  for (let i = 0; i < num_inspections; i++) {
    labels_oval.push(
      labels_oval_supplier[i] + " " + labels_oval_date_production[i]
    );
  }

  const labels_strip = [];
  const labels_strip_supplier =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
      .ADDITIONAL_SUPPLY_INFO_LABELS_STRIP.SUPPLIER.VALUES;
  const labels_strip_date_production =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
      .ADDITIONAL_SUPPLY_INFO_LABELS_STRIP.DATE_PRODUCTION.VALUES;
  for (let i = 0; i < num_inspections; i++) {
    labels_strip.push(
      labels_strip_supplier[i] + " " + labels_strip_date_production[i]
    );
  }

  let up = -5;

  pdf.setFontSize(9).setFont(undefined, "bold");
  pdf.text(["Información Adicional de Suministros"], 15, 215 - up, {
    align: "left",
  });
  pdf.setFontSize(7).setFont(undefined, "normal");
  const show_additional_supply_info_labels = false;

  if (show_additional_supply_info_labels) {
    pdf.text("Fecha de Producción:", 15, 173);
    pdf.text("Forma de Pegado", 178, 178);
    pdf.text(
      ["Autoad.", "Autoad.", "Autoad.", "Autoad.", "Autoad.", "Autoad."],
      178,
      187.5,
      { lineHeightFactor: 2 }
    );
    pdf.text(
      [
        "Cola Fría",
        "Cola Fría",
        "Cola Fría",
        "Cola Fría",
        "Cola Fría",
        "Cola Fría",
      ],
      192,
      187.5,
      { lineHeightFactor: 2 }
    );
    pdf.text("Etiquetas", 18, 200, { angle: 90 });
    pdf.text("Proveedor / Fecha de Prod.", 45, 210, { angle: 90 });
    pdf.text(
      [
        "HORA:",
        "Frente:",
        "Recetario:",
        "Cuello:",
        "Hombro:",
        "Ovalo:",
        "Strip:",
      ],
      23,
      182,
      { lineHeightFactor: 2 }
    );
    pdf.autoTable({
      head: [
        [
          "Inspección 1",
          "Inspección 2",
          "Inspección 3",
          "Inspección 4",
          "Inspección 5",
        ],
      ],
      body: [
        data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
          .HOUR_LABEL.VALUES,
        labels_front,
        labels_recipebook,
        labels_neck,
        labels_shoulder,
        labels_oval,
        labels_strip,
      ],
      startY: 174,
      margin: { left: 50, right: 44 },
      styles: { fontSize: 7, cellPadding: 1 },
      theme: "grid",
      headStyles: { halign: "center", fillColor: [41, 128, 186] },
    });

    let gluing_method =
      data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
        .ADDITIONAL_SUPPLY_INFO_LABELS_FRONT.GLUING_METHOD.VALUES[0];
    let cold_glue = "Off";
    let self_adhesive = "Off";
    if (gluing_method === "COLD_GLUE") cold_glue = "On";
    else if (gluing_method === "SELF_ADHESIVE") self_adhesive = "On";

    let colafria2 = new AcroFormCheckBox();
    colafria2.Rect = [188, 184.5, 3, 3];
    addCheckBox(colafria2, pdf, cold_glue, 8);

    let autoad2 = new AcroFormCheckBox();
    autoad2.Rect = [174, 184.5, 3, 3];
    addCheckBox(autoad2, pdf, self_adhesive, 8);

    gluing_method =
      data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
        .ADDITIONAL_SUPPLY_INFO_LABELS_RECIPEBOOK.GLUING_METHOD.VALUES[0];
    cold_glue = "Off";
    self_adhesive = "Off";
    if (gluing_method === "COLD_GLUE") cold_glue = "On";
    else if (gluing_method === "SELF_ADHESIVE") self_adhesive = "On";

    let colafria3 = new AcroFormCheckBox();
    colafria3.Rect = [188, 189.5, 3, 3];
    addCheckBox(colafria3, pdf, cold_glue, 8);

    let autoad3 = new AcroFormCheckBox();
    autoad3.Rect = [174, 189.5, 3, 3];
    addCheckBox(autoad3, pdf, self_adhesive, 8);

    gluing_method =
      data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
        .ADDITIONAL_SUPPLY_INFO_LABELS_NECK.GLUING_METHOD.VALUES[0];
    cold_glue = "Off";
    self_adhesive = "Off";
    if (gluing_method === "COLD_GLUE") cold_glue = "On";
    else if (gluing_method === "SELF_ADHESIVE") self_adhesive = "On";

    let colafria4 = new AcroFormCheckBox();
    colafria4.Rect = [188, 194.5, 3, 3];
    addCheckBox(colafria4, pdf, cold_glue, 8);

    let autoad4 = new AcroFormCheckBox();
    autoad4.Rect = [174, 194.5, 3, 3];
    addCheckBox(autoad4, pdf, self_adhesive, 8);

    gluing_method =
      data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
        .ADDITIONAL_SUPPLY_INFO_LABELS_SHOULDER.GLUING_METHOD.VALUES[0];
    cold_glue = "Off";
    self_adhesive = "Off";
    if (gluing_method === "COLD_GLUE") cold_glue = "On";
    else if (gluing_method === "SELF_ADHESIVE") self_adhesive = "On";

    let colafria5 = new AcroFormCheckBox();
    colafria5.Rect = [188, 199.5, 3, 3];
    addCheckBox(colafria5, pdf, cold_glue, 8);

    let autoad5 = new AcroFormCheckBox();
    autoad5.Rect = [174, 199.5, 3, 3];
    addCheckBox(autoad5, pdf, self_adhesive, 8);

    gluing_method =
      data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
        .ADDITIONAL_SUPPLY_INFO_LABELS_OVAL.GLUING_METHOD.VALUES[0];
    cold_glue = "Off";
    self_adhesive = "Off";
    if (gluing_method === "COLD_GLUE") cold_glue = "On";
    else if (gluing_method === "SELF_ADHESIVE") self_adhesive = "On";

    let colafria6 = new AcroFormCheckBox();
    colafria6.Rect = [188, 204.5, 3, 3];
    addCheckBox(colafria6, pdf, cold_glue, 8);

    let autoad6 = new AcroFormCheckBox();
    autoad6.Rect = [174, 204.5, 3, 3];
    addCheckBox(autoad6, pdf, self_adhesive, 8);

    gluing_method =
      data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_LABELS
        .ADDITIONAL_SUPPLY_INFO_LABELS_STRIP.GLUING_METHOD.VALUES[0];
    cold_glue = "Off";
    self_adhesive = "Off";
    if (gluing_method === "COLD_GLUE") cold_glue = "On";
    else if (gluing_method === "SELF_ADHESIVE") self_adhesive = "On";

    let colafria7 = new AcroFormCheckBox();
    colafria7.Rect = [188, 209.5, 3, 3];
    addCheckBox(colafria7, pdf, cold_glue, 8);

    let autoad7 = new AcroFormCheckBox();
    autoad7.Rect = [174, 209.5, 3, 3];
    addCheckBox(autoad7, pdf, self_adhesive, 8);
  }

  const individual_pack = [];
  const date_production =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_INDIVIDUAL_PACK
      .DATE_PRODUCTION.VALUES;
  const hour_individual_pack =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_INDIVIDUAL_PACK
      .HOUR_INDIVIDUAL_PACK.VALUES;
  const supplier_individual_pack =
    data.body.ADDITIONAL_SUPPLY_INFO.ADDITIONAL_SUPPLY_INFO_INDIVIDUAL_PACK
      .SUPPLIER_INDIVIDUAL_PACK.VALUES;

  /*for (let i = 0; i < num_inspections; i++) {
    individual_pack.push(
      date_production[i] +
        ' ' +
        hour_individual_pack[i] +
        ' ' +
        supplier_individual_pack[i]
    );
  }*/
  for (let i = 0; i < num_inspections; i++) {
    individual_pack.push(
      supplier_individual_pack[i] + " " + date_production[i]
    );
  }

  pdf.text("Cajetilla Individual", 17, 245 - up, { angle: 90 });
  pdf.text(["Hora:"], 19, 232 - up, { lineHeightFactor: 1.5 });
  pdf.text("Proveedor / Fecha de Prod.", 19, 238 - up);
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [hour_individual_pack, individual_pack],
    startY: 224 - up,
    margin: { left: 50 },
    styles: { fontSize: 7, cellPadding: 1, minCellHeight: 6 },
    theme: "grid",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  let package_defects = [];
  const package_defects_minor_defect_k =
    data.body.PACKAGE_DEFECTS.PACKAGE_DEFECTS_MINOR.DEFECT_K.VALUES;
  for (let i = 0; i < num_inspections; i++) {
    let minor = [
      data.body.PACKAGE_DEFECTS.PACKAGE_DEFECTS_MINOR.DEFECT_K.VALUES_ALT[i],
      data.body.PACKAGE_DEFECTS.PACKAGE_DEFECTS_MINOR.DEFECT_L.VALUES_ALT[i],
    ];
    package_defects.push(concatElements(minor));
    let mayor = [
      data.body.PACKAGE_DEFECTS.PACKAGE_DEFECTS_MAYOR.DEFECT_H.VALUES_ALT[i],
      data.body.PACKAGE_DEFECTS.PACKAGE_DEFECTS_MAYOR.DEFECT_I.VALUES_ALT[i],
      data.body.PACKAGE_DEFECTS.PACKAGE_DEFECTS_MAYOR.DEFECT_J.VALUES_ALT[i],
    ];
    package_defects.push(concatElements(mayor));
    let critical = [
      data.body.PACKAGE_DEFECTS.PACKAGE_DEFECTS_CRITICAL.DEFECT_A.VALUES_ALT[i],
      data.body.PACKAGE_DEFECTS.PACKAGE_DEFECTS_CRITICAL.DEFECT_B.VALUES_ALT[i],
      data.body.PACKAGE_DEFECTS.PACKAGE_DEFECTS_CRITICAL.DEFECT_C.VALUES_ALT[i],
      data.body.PACKAGE_DEFECTS.PACKAGE_DEFECTS_CRITICAL.DEFECT_D.VALUES_ALT[i],
      data.body.PACKAGE_DEFECTS.PACKAGE_DEFECTS_CRITICAL.DEFECT_E.VALUES_ALT[i],
      data.body.PACKAGE_DEFECTS.PACKAGE_DEFECTS_CRITICAL.DEFECT_F.VALUES_ALT[i],
      data.body.PACKAGE_DEFECTS.PACKAGE_DEFECTS_CRITICAL.DEFECT_G.VALUES_ALT[i],
    ];
    package_defects.push(concatElements(critical));
  }

  let liquid_defects = [];
  for (let i = 0; i < num_inspections; i++) {
    let minor = [];
    liquid_defects.push(concatElements(minor));
    let mayor = [
      data.body.LIQUID_DEFECTS.LIQUID_DEFECTS_MAYOR.DEFECT_O.VALUES_ALT[i],
      data.body.LIQUID_DEFECTS.LIQUID_DEFECTS_MAYOR.DEFECT_P.VALUES_ALT[i],
    ];
    liquid_defects.push(concatElements(mayor));
    let critical = [
      data.body.LIQUID_DEFECTS.LIQUID_DEFECTS_CRITICAL.DEFECT_M.VALUES_ALT[i],
      data.body.LIQUID_DEFECTS.LIQUID_DEFECTS_CRITICAL.DEFECT_N.VALUES_ALT[i],
    ];
    liquid_defects.push(concatElements(critical));
  }

  let caps_defects = [];
  for (let i = 0; i < num_inspections; i++) {
    let minor = [
      data.body.CAPS_DEFECTS.CAPS_DEFECTS_CAPS.CAPS_DEFECTS_CAPS_MINOR.DEFECT_W
        .VALUES_ALT[i],
    ];
    caps_defects.push(concatElements(minor));
    let mayor = [
      data.body.CAPS_DEFECTS.CAPS_DEFECTS_CAPS.CAPS_DEFECTS_CAPS_MAYOR.DEFECT_Q
        .VALUES_ALT[i],
      data.body.CAPS_DEFECTS.CAPS_DEFECTS_CAPS.CAPS_DEFECTS_CAPS_MAYOR.DEFECT_R
        .VALUES_ALT[i],
      data.body.CAPS_DEFECTS.CAPS_DEFECTS_CAPS.CAPS_DEFECTS_CAPS_MAYOR.DEFECT_S
        .VALUES_ALT[i],
      data.body.CAPS_DEFECTS.CAPS_DEFECTS_CAPS.CAPS_DEFECTS_CAPS_MAYOR.DEFECT_T
        .VALUES_ALT[i],
      data.body.CAPS_DEFECTS.CAPS_DEFECTS_CAPS.CAPS_DEFECTS_CAPS_MAYOR.DEFECT_U
        .VALUES_ALT[i],
      data.body.CAPS_DEFECTS.CAPS_DEFECTS_CAPS.CAPS_DEFECTS_CAPS_MAYOR.DEFECT_V
        .VALUES_ALT[i],
    ];
    caps_defects.push(concatElements(mayor));
    let critical = [];
    caps_defects.push(concatElements(critical));
  }

  let cork_defects = [];
  for (let i = 0; i < num_inspections; i++) {
    let minor = [
      data.body.CAPS_DEFECTS.CAPS_DEFECTS_CORK.CAPS_DEFECTS_CORK_MINOR
        .DEFECT_CORK_MINOR.VALUES[i],
    ];
    cork_defects.push(concatElements(minor));
    let mayor = [
      data.body.CAPS_DEFECTS.CAPS_DEFECTS_CORK.CAPS_DEFECTS_CORK_MAYOR
        .DEFECT_CORK_MAYOR.VALUES[i],
    ];
    cork_defects.push(concatElements(mayor));
    let critical = [
      data.body.CAPS_DEFECTS.CAPS_DEFECTS_CORK.CAPS_DEFECTS_CORK_CRITICAL
        .DEFECT_CORK_CRITICAL.VALUES[i],
    ];
    cork_defects.push(concatElements(critical));
  }

  let capsule_defects = [];
  for (let i = 0; i < num_inspections; i++) {
    let minor = [
      data.body.CAPS_DEFECTS.CAPS_DEFECTS_CAPSULE.CAPS_DEFECTS_CAPSULE_MINOR
        .DEFECT_CAPSULE_MINOR.VALUES[i],
    ];
    capsule_defects.push(concatElements(minor));
    let mayor = [
      data.body.CAPS_DEFECTS.CAPS_DEFECTS_CAPSULE.CAPS_DEFECTS_CAPSULE_MAYOR
        .DEFECT_CAPSULE_MAYOR.VALUES[i],
    ];
    capsule_defects.push(concatElements(mayor));
    let critical = [
      data.body.CAPS_DEFECTS.CAPS_DEFECTS_CAPSULE.CAPS_DEFECTS_CAPSULE_CRITICAL
        .DEFECT_CAPSULE_CRITICAL.VALUES[i],
    ];
    capsule_defects.push(concatElements(critical));
  }

  let label_defects_front = [];
  for (let i = 0; i < num_inspections; i++) {
    let minor = [
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MINOR
        .DEFECT_AD.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MINOR
        .DEFECT_AL.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MINOR
        .DEFECT_AE.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MINOR
        .DEFECT_AM.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MINOR
        .DEFECT_AF.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MINOR
        .DEFECT_AN.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MINOR
        .DEFECT_AG.VALUES_ALT[i],
    ];
    label_defects_front.push(concatElements(minor));
    let mayor = [
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MAYOR
        .DEFECT_X.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MAYOR
        .DEFECT_AH.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MAYOR
        .DEFECT_Y.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MAYOR
        .DEFECT_AI.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MAYOR
        .DEFECT_AA.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MAYOR
        .DEFECT_AJ.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MAYOR
        .DEFECT_AB.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MAYOR
        .DEFECT_AK.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_FRONT.LABEL_DEFECTS_FRONT_MAYOR
        .DEFECT_AC.VALUES_ALT[i],
    ];
    label_defects_front.push(concatElements(mayor));
    let critical = [];
    label_defects_front.push(concatElements(critical));
  }

  let label_defects_recipebook = [];
  for (let i = 0; i < num_inspections; i++) {
    let minor = [
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MINOR.DEFECT_AD.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MINOR.DEFECT_AL.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MINOR.DEFECT_AE.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MINOR.DEFECT_AM.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MINOR.DEFECT_AF.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MINOR.DEFECT_AN.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MINOR.DEFECT_AG.VALUES_ALT[i],
    ];
    label_defects_recipebook.push(concatElements(minor));
    let mayor = [
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MAYOR.DEFECT_X.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MAYOR.DEFECT_AH.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MAYOR.DEFECT_Y.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MAYOR.DEFECT_AI.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MAYOR.DEFECT_AA.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MAYOR.DEFECT_AJ.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MAYOR.DEFECT_AB.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MAYOR.DEFECT_AK.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_RECIPEBOOK
        .LABEL_DEFECTS_RECIPEBOOK_MAYOR.DEFECT_AC.VALUES_ALT[i],
    ];
    label_defects_recipebook.push(concatElements(mayor));
    let critical = [];
    label_defects_recipebook.push(concatElements(critical));
  }
  let label_defects_strip = [];
  for (let i = 0; i < num_inspections; i++) {
    let minor = [
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MINOR
        .DEFECT_AD.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MINOR
        .DEFECT_AL.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MINOR
        .DEFECT_AE.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MINOR
        .DEFECT_AM.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MINOR
        .DEFECT_AF.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MINOR
        .DEFECT_AN.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MINOR
        .DEFECT_AG.VALUES_ALT[i],
    ];
    label_defects_strip.push(concatElements(minor));
    let mayor = [
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MAYOR
        .DEFECT_X.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MAYOR
        .DEFECT_AH.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MAYOR
        .DEFECT_Y.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MAYOR
        .DEFECT_AI.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MAYOR
        .DEFECT_AA.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MAYOR
        .DEFECT_AJ.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MAYOR
        .DEFECT_AB.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MAYOR
        .DEFECT_AK.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_STRIP.LABEL_DEFECTS_STRIP_MAYOR
        .DEFECT_AC.VALUES_ALT[i],
    ];
    label_defects_strip.push(concatElements(mayor));
    let critical = [];
    label_defects_strip.push(concatElements(critical));
  }

  let label_defects_shoulder = [];
  for (let i = 0; i < num_inspections; i++) {
    let minor = [
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MINOR.DEFECT_AD.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MINOR.DEFECT_AL.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MINOR.DEFECT_AE.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MINOR.DEFECT_AM.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MINOR.DEFECT_AF.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MINOR.DEFECT_AN.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MINOR.DEFECT_AG.VALUES_ALT[i],
    ];
    label_defects_shoulder.push(concatElements(minor));
    let mayor = [
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MAYOR.DEFECT_X.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MAYOR.DEFECT_AH.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MAYOR.DEFECT_Y.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MAYOR.DEFECT_AI.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MAYOR.DEFECT_AA.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MAYOR.DEFECT_AJ.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MAYOR.DEFECT_AB.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MAYOR.DEFECT_AK.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_SHOULDER
        .LABEL_DEFECTS_SHOULDER_MAYOR.DEFECT_AC.VALUES_ALT[i],
    ];
    label_defects_shoulder.push(concatElements(mayor));
    let critical = [];
    label_defects_shoulder.push(concatElements(critical));
  }

  let label_defects_neck = [];
  for (let i = 0; i < num_inspections; i++) {
    let minor = [
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MINOR
        .DEFECT_AD.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MINOR
        .DEFECT_AL.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MINOR
        .DEFECT_AE.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MINOR
        .DEFECT_AM.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MINOR
        .DEFECT_AF.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MINOR
        .DEFECT_AN.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MINOR
        .DEFECT_AG.VALUES_ALT[i],
    ];
    label_defects_neck.push(concatElements(minor));
    let mayor = [
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MAYOR
        .DEFECT_X.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MAYOR
        .DEFECT_AH.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MAYOR
        .DEFECT_Y.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MAYOR
        .DEFECT_AI.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MAYOR
        .DEFECT_AA.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MAYOR
        .DEFECT_AJ.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MAYOR
        .DEFECT_AB.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MAYOR
        .DEFECT_AK.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_NECK.LABEL_DEFECTS_NECK_MAYOR
        .DEFECT_AC.VALUES_ALT[i],
    ];
    label_defects_neck.push(concatElements(mayor));
    let critical = [];
    label_defects_neck.push(concatElements(critical));
  }

  let label_defects_oval = [];
  for (let i = 0; i < num_inspections; i++) {
    let minor = [
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MINOR
        .DEFECT_AD.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MINOR
        .DEFECT_AL.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MINOR
        .DEFECT_AE.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MINOR
        .DEFECT_AM.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MINOR
        .DEFECT_AF.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MINOR
        .DEFECT_AN.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MINOR
        .DEFECT_AG.VALUES_ALT[i],
    ];
    label_defects_oval.push(concatElements(minor));
    let mayor = [
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MAYOR
        .DEFECT_X.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MAYOR
        .DEFECT_AH.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MAYOR
        .DEFECT_Y.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MAYOR
        .DEFECT_AI.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MAYOR
        .DEFECT_AA.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MAYOR
        .DEFECT_AJ.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MAYOR
        .DEFECT_AB.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MAYOR
        .DEFECT_AK.VALUES_ALT[i],
      data.body.LABEL_DEFECTS.LABEL_DEFECTS_OVAL.LABEL_DEFECTS_OVAL_MAYOR
        .DEFECT_AC.VALUES_ALT[i],
    ];
    label_defects_oval.push(concatElements(mayor));
    let critical = [];
    label_defects_oval.push(concatElements(critical));
  }

  let inspection_summary = [];
  let quantity_inspected = [];
  for (let i = 0; i < num_inspections; i++) {
    inspection_summary.push(
      data.body.INSPECTION_SUMMARY.INSPECTION_SUMMARY_SUMMARY.TOTAL_MINOR
        .VALUES[i]
    );
    inspection_summary.push(
      data.body.INSPECTION_SUMMARY.INSPECTION_SUMMARY_SUMMARY.TOTAL_MAYOR
        .VALUES[i]
    );
    inspection_summary.push(
      data.body.INSPECTION_SUMMARY.INSPECTION_SUMMARY_SUMMARY.TOTAL_CRITICAL
        .VALUES[i]
    );
    quantity_inspected.push({
      content:
        data.body.INSPECTION_SUMMARY.INSPECTION_SUMMARY_SUMMARY
          .QUANTITY_INSPECTED.VALUES[i],
      colSpan: 3,
    });
  }

  pdf.addPage();
  pdf.addImage(logo, "PNG", 15, 8, 14, 14);
  pdf.setFontSize(11).setFont("helvetica", "bold");
  pdf.text(
    ["INSPECCIÓN DE PRODUCTO TERMINADO", "Varela Hermanos S.A."],
    107.95,
    11,
    { align: "center" }
  );
  pdf.setFontSize(10);
  pdf.text(["DEFECTOS"], 107.95, 21, { align: "center" });
  pdf.text(["TIPOS DE DEFECTOS"], 107.95, 110, { align: "center" });

  pdf.autoTable({
    head: [
      [
        {
          content: "",
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Inspección 1",
          colSpan: 3,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Inspección 2",
          colSpan: 3,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Inspección 3",
          colSpan: 3,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Inspección 4",
          colSpan: 3,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Inspección 5",
          colSpan: 3,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
      ],
      [
        {
          content: "",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Menor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Mayor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Crítico",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Menor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Mayor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Crítico",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Menor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Mayor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Crítico",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Menor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Mayor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Crítico",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Menor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Mayor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Crítico",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Menor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Mayor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Crítico",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
      ],
    ],
    body: [
      ["Envases", ...package_defects],
      ["Líquido", ...liquid_defects],
      ["Tapas", ...caps_defects],
      ["Cápsula", ...capsule_defects],
      ["Corcho", ...cork_defects],
      ["Etiqueta Frente", ...label_defects_front],
      ["Etiqueta Recetario", ...label_defects_recipebook],
      ["Etiqueta Strip", ...label_defects_strip],
      ["Etiqueta Hombro", ...label_defects_shoulder],
      ["Etiqueta Cuello", ...label_defects_neck],
      ["Etiqueta Óvalo", ...label_defects_oval],
      ["Total de unidades defectuosas por inspección", ...inspection_summary],
      [{ content: "Cantidad Inspeccionada" }, ...quantity_inspected],
    ],
    startY: 23,
    styles: { fontSize: 7, cellPadding: 1 },
    columnStyles: {
      0: { fillColor: [221, 221, 221], textColor: [0, 0, 0] },
      4: { fillColor: [245, 245, 245] },
      5: { fillColor: [245, 245, 245] },
      6: { fillColor: [245, 245, 245] },
      10: { fillColor: [245, 245, 245] },
      11: { fillColor: [245, 245, 245] },
      12: { fillColor: [245, 245, 245] },
    },
    theme: "grid",
  });

  const package_other =
    data.body.INSPECTION_SUMMARY.INSPECTION_SUMMARY_PACKAGE.TOTAL_OTHER.VALUES.reduce(
      (partialSum, a) => partialSum + parseInt(a),
      0
    );
  const liquid_other =
    data.body.INSPECTION_SUMMARY.INSPECTION_SUMMARY_LIQUID.TOTAL_OTHER.VALUES.reduce(
      (partialSum, a) => partialSum + parseInt(a),
      0
    );
  const caps_other =
    data.body.INSPECTION_SUMMARY.INSPECTION_SUMMARY_CAPS.TOTAL_OTHER.VALUES.reduce(
      (partialSum, a) => partialSum + parseInt(a),
      0
    );
  const label_other =
    data.body.INSPECTION_SUMMARY.INSPECTION_SUMMARY_LABEL.TOTAL_OTHER.VALUES.reduce(
      (partialSum, a) => partialSum + parseInt(a),
      0
    );

  pdf.autoTable({
    head: [
      [
        {
          content: "",
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Envases",
          colSpan: 2,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Líquido",
          colSpan: 2,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Tapas",
          colSpan: 2,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Etiquetas",
          colSpan: 4,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
      ],
    ],
    body: [
      [
        { content: "Defecto Crítico", rowSpan: 7 },
        { content: "Columpio (A)" },
        { content: "" },
        {
          content: "Color no\nCaracterístico (M)",
          rowSpan: 4,
          styles: { valign: "center" },
        },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
      ],
      [{ content: "Rajaduras (B)" }],
      [{ content: "Aguja (C)" }],
      [{ content: "Aristas Cortante (D)" }],
      [
        { content: "Vidrio Suelto (E)" },
        { content: "" },
        {
          content: "Materia Extraña (N)",
          rowSpan: 3,
          styles: { valign: "center" },
        },
        { content: "", rowSpan: 3 },
        { content: "", rowSpan: 3 },
        { content: "", rowSpan: 3 },
        { content: "", rowSpan: 3 },
        { content: "", rowSpan: 3 },
        { content: "", rowSpan: 3 },
        { content: "", rowSpan: 3 },
      ],
      [{ content: "Vidrio Adherido (F)" }],
      [{ content: "Puente Roto (G)" }],

      [
        { content: "Defecto Mayor", rowSpan: 6 },
        { content: "Envase Rayado (H)" },
        { content: "" },
        { content: "Nivel Alto (O)", rowSpan: 2 },
        { content: "", rowSpan: 2 },
        { content: "Liqueo en Tapas (Q)" },
        { content: "" },
        { content: "Etiqueta Torcida (X)" },
        { content: "" },
        { content: "Defecto de Impresión (AH)" },
        { content: "" },
      ],

      [
        { content: "Falta de Codificación (I)", rowSpan: 2 },
        { content: "", rowSpan: 2 },
        { content: "Fecha Incorrecta (R)" },
        { content: "" },
        { content: "Etiqueta Equivocada (Y)" },
        { content: "" },
        { content: "Color Incorrecto (AI)" },
        { content: "" },
      ],

      [
        { content: "Nivel Bajo (P)", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "Tapa Rota (S)" },
        { content: "" },
        { content: "Falta de Etiqueta (AA)" },
        { content: "" },
        { content: "Invertidas (AJ)" },
        { content: "" },
      ],

      [
        { content: "Envase Manchado (J)", rowSpan: 3 },
        { content: "", rowSpan: 3 },
        { content: "Diferencia Excesiva\nen el Tono de Color (T)" },
        { content: "" },
        { content: "Leyenda Equivocada (AB)" },
        { content: "" },
        {
          content: "Diferencia Excesiva\nen el Tono de Color (AK)",
          rowSpan: 3,
        },
        { content: "", rowSpan: 3 },
      ],

      [
        { content: "Puente Roto (U)" },
        { content: "" },
        { content: "Etiqueta Arrugada (AC)", rowSpan: 2 },
        { content: "", rowSpan: 2 },
      ],

      [{ content: "Código Incorrecto (V)" }, { content: "" }],

      [
        { content: "Defecto Menor", rowSpan: 4 },
        { content: "Disparejo (K)" },
        { content: "" },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "Tapa Floja (W)", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "Etiqueta Rota (AD)" },
        { content: "" },
        { content: "Sucias (AL)" },
        { content: "" },
      ],
      [
        { content: "Fracturas que no Afectan la Resistencia (L)", rowSpan: 3 },
        { content: "", rowSpan: 3 },
        { content: "Exceso de Pegamento (AE)" },
        { content: "" },
        { content: "Etiqueta Pelada (AM)" },
        { content: "" },
      ],
      [
        { content: "Falta de Pegamento (AF)" },
        { content: "" },
        { content: "Etiqueta Doblada (AN)" },
        { content: "" },
      ],
      [
        { content: "Fuera de Centro (AG)" },
        { content: "" },
        { content: "" },
        { content: "" },
      ],
      [
        { content: "" },
        { content: "Otros (Z)" },
        { content: package_other },
        { content: "Otros (Z)" },
        { content: liquid_other },
        { content: "Otros (Z)" },
        { content: caps_other },
        { content: "" },
        { content: "" },
        { content: "Otros (Z)" },
        { content: label_other },
      ],
    ],
    startY: 121,
    styles: { fontSize: 7, cellPadding: 1, halign: "center", valign: "middle" },
    columnStyles: {
      0: { fillColor: [221, 221, 221], textColor: [0, 0, 0] },
      3: { fillColor: [245, 245, 245] },
      4: { fillColor: [245, 245, 245] },
      7: { fillColor: [245, 245, 245] },
      8: { fillColor: [245, 245, 245] },
    },
    theme: "grid",
  });

  let observacion = data.body.FORM_SUMMARY.OBSERVATIONS.VALUES[0];

  const total_defects = data.body.FORM_SUMMARY.TOTAL_DEFECTS.VALUES[0];
  const total_critical = data.body.FORM_SUMMARY.TOTAL_CRITICAL.VALUES[0];
  const total_mayor = data.body.FORM_SUMMARY.TOTAL_MAYOR.VALUES[0];
  const total_minor = data.body.FORM_SUMMARY.TOTAL_MINOR.VALUES[0];

  pdf.setFontSize(8);
  pdf.text(
    [
      "TOTAL DE DEFECTOS " + total_defects,
      "TOTAL DE UNIDADES DEFECTUOSAS CRÍTICO " + total_critical,
    ],
    15,
    230,
    { lineHeightFactor: 2 }
  );
  pdf.text(
    [
      "TOTAL DE UNIDADES DEFECTUOSAS MAYOR " + total_mayor,
      "TOTAL DE UNIDADES DEFECTUOSAS MENOR " + total_minor,
    ],
    120,
    230,
    { lineHeightFactor: 2 }
  );
  pdf.text("Observaciones", 15, 242, { align: "justify", maxWidth: 186 });
  pdf.setFont(undefined, "normal");
  pdf.text(observacion, 15, 247, { align: "justify", maxWidth: 186 });

  pdf.text(
    ["________________________________", "Aseguramiento de la Calidad"],
    59,
    265,
    { align: "center" }
  );
  pdf.text(["________________________________", "Operaciones"], 170, 265, {
    align: "center",
  });

  pdf.setFont(undefined, "bold");
  pdf.text("Informado:", 130, 265);
  pdf.text("Revisado por:", 15, 265);

  pdf.setFontSize(9);

  const sign_quality_assurance =
    data.body.SIGNATURES.QUALITY_ASSURANCE.VALUES[0];
  pdf.text(sign_quality_assurance, 36, 264, { align: "justify" });

  const sign_operations = data.body.SIGNATURES.OPERATIONS.VALUES[0];
  pdf.text(sign_operations, 147, 264, { align: "justify" });

  return pdf.output("bloburl");
}

export default PdfProduct;
