/**
 * Component PdfEmpty
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2023-07-12
 */
import jsPDF from "jspdf";

export default function PdfEmpty() {
  const pdf = new jsPDF({ unit: "px", pageSize: "letter" });
  var width = pdf.internal.pageSize.getWidth();
  console.log("Ejecutando PdfEmpty");

  //var height = pdf.internal.pageSize.getHeight()
  pdf.setFontSize(14);
  pdf.setLineHeightFactor(2);
  pdf.text("", width / 2, 20, { align: "center" });

  return pdf.output("bloburl");
}
