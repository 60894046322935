import React, { useEffect, useState } from "react";
// import "./pivot2.css";
//import * as WebDataRocksReact from "react-webdatarocks";
import "@webdatarocks/webdatarocks/webdatarocks.min.css";
import * as WebDataRocksReact from "@webdatarocks/react-webdatarocks";

const Pivot2 = (props) => {
  return (
    <WebDataRocksReact.Pivot report="https://cdn.webdatarocks.com/reports/report.json" />
  );
};
export default Pivot2;
