/**
 * Component FormContent
 * @author: Luis Avello
 * @version 1.7.0
 * @updated 2023-10-05
 */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";

import FormSection from "./form_section";

const FormContent = (props) => {
  const { data, forminstance_id, info_order } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [errorStep, setErrorStep] = React.useState(
    new Array(data.sections.length).fill(false)
  );
  const [validate, setValidate] = React.useState(
    new Array(data.sections.length).fill(0)
  );

  const validateSection = () => {
    console.log("Validate Active Section " + activeStep);

    setValidate((prevValidate) => {
      return prevValidate.map((item, i) => {
        return i === activeStep ? item + 1 : item;
      });
    });
  };

  const handleViewPDF = () => {
    console.log("Go to View PDF");
    // window.location = "/quality/pdf_preview?code=" + data.code + "&order_id=" + data.order.id;
    //window.location =  "/quality/pdf?code=" + data.code + "&order_id=" + data.order.id;
    window.location = "/quality/pdf?formmain_id=" + data.formmain_id;
  };

  const handleNextStep = () => {
    window.navigator.vibrate(100);
    console.log("Next Step with vibration");
    validateSection();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    window.navigator.vibrate(100);
    console.log("Back Step with vibration");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClickStep = (index) => {
    console.log("Click Step");
    validateSection();
    setActiveStep(index);
  };

  const handleErrorStep = (index, value) => {
    console.log("-> handleErrorStep index " + index + " value " + value);

    if (errorStep[index] != value) {
      console.log(" Cambio de valor en errorStep");
      const newErrorStep = errorStep.map((item, i) => {
        return i === index ? value : item;
      });
      setErrorStep(newErrorStep);
    }
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {data.sections &&
        data.sections.map((section, index) => (
          <Step
            key={section.id}
            sx={{
              "& .MuiStepLabel-root .Mui-completed": {
                color: "green",
              },
              "& .MuiStepLabel-root .Mui-error": {
                color: "red",
              },
            }}
          >
            <StepLabel
              error={errorStep[index]}
              onClick={() => handleClickStep(index)}
              sx={{ textTransform: "uppercase" }}
              optional={
                index == activeStep ? (
                  <Typography variant="caption">{info_order}</Typography>
                ) : null
              }
            >
              {section.name}
            </StepLabel>
            <StepContent TransitionProps={{ unmountOnExit: true }}>
              <FormSection
                section={section}
                grid={data.config.grid}
                index={index}
                handleError={handleErrorStep}
                validate={validate[index]}
                forminstance_id={forminstance_id}
              />

              <Box sx={{ mb: 0 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={
                      index === data.sections.length - 1
                        ? handleViewPDF
                        : handleNextStep
                    }
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === data.sections.length - 1
                      ? "Ver PDF"
                      : "Siguiente"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBackStep}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Volver
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
    </Stepper>
  );
};

export default FormContent;
