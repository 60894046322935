/**
 * Component PdfViewer
 * @author: Luis Avello
 * @version 1.2.1
 * @updated 2023-07-27
 */
import React, { useState, useEffect, useRef } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import * as pdfjs from "pdfjs-dist";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PdfViewer = (props) => {
  const { data } = props;

  useEffect(() => {
    console.log("Pdfjs version " + pdfjs.version);
  }, []);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div>
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
      >
        <Viewer fileUrl={data} plugins={[defaultLayoutPluginInstance]} />
      </Worker>
    </div>
  );
};

export default PdfViewer;
